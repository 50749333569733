<div class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-12 col-md-4">
      <div>
        <div class="my-3">
          <img
            class="img-fluid rounded"
            style="max-height: 100px"
            src="/assets/images/rtgs-upi.png"
          />
        </div>
        <h2>Direct to Bank Account Payment</h2>
        <p>
          Send your payment for the order amount through NEFT/RTGS or UPI and
          capture the screenshot below for reference. Payment reference
          Information will be validated against account transactions and order
          status will be updated. Payment received confirmation will be send to
          your registered email address
        </p>
      </div>
      <div>
        <button
          mat-raised-button
          color="warn"
          (click)="$event.preventDefault(); changePaymentOption()"
          class="mt-3 mb-3"
          type="button"
        >
          Change Payment Option
        </button>
      </div>

      <div>
        <form [formGroup]="paymentFormGroup">
          <div
            *ngIf="showFileErrorMessage"
            class="alert alert-danger"
            role="alert"
          >
            Please select less than number of allowed files (Max 10)
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Select Payment Screenshot</mat-label>
            <ngx-mat-file-input
              multiple="false"
              formControlName="paymentscreen"
              (change)="fileChangeEvent($event)"
              placeholder=" Select Screenshot"
            >
            </ngx-mat-file-input>
            <mat-icon matSuffix>attachment</mat-icon>
            <mat-error
              *ngIf="paymentscreen && paymentscreen.hasError('required')"
            >
              Please select a file
            </mat-error>
            <mat-error
              *ngIf="paymentscreen && paymentscreen.hasError('maxContentSize')"
            >
              The total size must not exceed
              {{
                paymentscreen?.getError("maxContentSize").maxSize | byteFormat
              }}
              ({{
                paymentscreen?.getError("maxContentSize").actualSize
                  | byteFormat
              }}).
            </mat-error>
          </mat-form-field>
          <ng-container class="mt-5" *ngIf="uploadInprogress">
            <mat-progress-bar
              mode="indeterminate"
              color="primary"
            ></mat-progress-bar>
          </ng-container>

          <button
            class="w-50 my-3"
            color="primary"
            mat-flat-button
            (click)="uploadScreenShot()"
          >
            Upload
          </button>

          <div class="row" *ngIf="screenshot">
            <mat-toolbar class="m-2" color="primary">
              <div>Preview Screenshot</div>
            </mat-toolbar>
            <mat-card class="my-3">
              <mat-card-content>
                <img
                  height=""
                  class="rounded img-fluid"
                  [src]="screenshot"
                  alt=""
                />
              </mat-card-content>
            </mat-card>
          </div>
        </form>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div>
        <h2>UPI Payment - QR Code(GPay)</h2>
        <div class="my-3">
          <img
            style="max-height: 50px"
            class="img-fluid rounded"
            src="/assets/images/upi.png"
          />
        </div>
        <img
          style="max-height: 500px"
          class="img-fluid rounded-lg"
          src="/assets/images/gpay.jpeg"
        />
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div>
        <h2>NEFT/RTGS Bank Information</h2>
        <div class="my-3">
          <img
            class="img-fluid rounded"
            style="max-height: 100px"
            src="/assets/images/rtgs.png"
          />
        </div>
        <div class="my-3">
          <img
            class="img-fluid rounded-circle"
            src="/assets/images/union bank.png"
          />
        </div>
        <table class="table">
          <tr>
            <td>Bank Name</td>
            <td>UNION BANK OF INDIA</td>
          </tr>
          <tr>
            <td>Account Type</td>
            <td>Overdraft (OD)</td>
          </tr>
          <tr>
            <td>A/c No.</td>
            <td>560361000110251</td>
          </tr>
          <tr>
            <td>IFS Code</td>
            <td>UBIN0904236</td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>NALLUR</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
