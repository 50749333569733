import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandingService } from 'tt-branding-lib';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private httpClient: HttpClient,
    private branding: BrandingService
  ) {}

  async initiateRazorPayment(oid: string) {
    let apiUrl =
      this.branding.config.api.baseUrl +
      this.branding.config.api.payment.razorinit;
    let url = apiUrl + 'oid=' + oid;
    return await this.httpClient.get(url).toPromise();
  }
}
