import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { BrandingService } from 'tt-branding-lib';
import { AngularFirestore } from '@angular/fire/compat/firestore';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl: string;

  getAllUser() {
    return this.store.collection(this.baseUrl + '/users').valueChanges();
  }

  checkUserRole(uid: string) {
    return this.store
      .collection(this.baseUrl + '/admins/')
      .doc(uid)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  logUsers(user: User) {
    return this.store
      .collection(this.baseUrl + '/userlog')
      .doc()
      .set(user);
  }

  async registerPreUser(user: any) {
    return this.getUser(user.uid).subscribe(async (data) => {
      if (data.exists) {
        return false;
      } else {
        await this.store
          .collection(this.baseUrl + '/pre-users')
          .doc(user.uid)
          .set(user)
          .then(() => {
            result: true;
          });
        return true;
      }
    });
  }

  async registerUser(user: any) {
    return this.getUser(user.uid).subscribe(async (data) => {
      if (data.exists) {
        return false;
      } else {
        await this.store
          .collection(this.baseUrl + '/users')
          .doc(user.uid)
          .set(user)
          .then(() => {
            result: true;
          });
        return true;
      }
    });
  }

  saveUser(user: User) {
    return this.store
      .collection(this.baseUrl + '/users')
      .doc(user.uid)
      .set(user);
  }

  getUser(uid: string) {
    return this.store
      .collection(this.baseUrl + '/users')
      .doc(uid)
      .get();
  }

  constructor(
    private store: AngularFirestore,
    private branding: BrandingService
  ) {
    this.baseUrl = branding.config.app.path;
  }
}
