<div class="container">
  <div *ngIf="order" class="mt-3 mb-3">
    <mat-toolbar color="accent">
      <h2>Order Id : {{ order?.id }}</h2>
    </mat-toolbar>
    <mat-card class="ml-2 mr-2">
      <div class="row">
        <div class="col-12">
          <div class="mb-1 mt-1">
            <strong>{{ order.name }}</strong>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="m-1">
            <mat-card color="warn">
              <div class="d-flex flex-column">
                <div><strong>Billing Address</strong></div>
                <div>{{ order.name }}</div>
                <div>{{ order.billing.address1 }}</div>
                <div>{{ order.billing.address2 }}</div>
                <div>{{ order.billing.city }}</div>
                <div>{{ order.billing.district }}</div>
                <div>{{ order.billing.state }}</div>
                <div>{{ order.billing.pincode }}</div>
                <div *ngIf="order.billing.landmark">
                  Landmark :{{ order.billing.landmark }}
                </div>
                <div class="mt-1">
                  Mobile: {{ order.billing.mobile }} <small></small>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="m-1">
            <mat-card color="warn">
              <div class="d-flex flex-column">
                <div><strong>Delivery Address</strong></div>
                <div>{{ order.delivery.name }}</div>
                <div>{{ order.delivery.address1 }}</div>
                <div>{{ order.delivery.address2 }}</div>
                <div>{{ order.delivery.city }}</div>
                <div>{{ order.delivery.district }}</div>
                <div>{{ order.delivery.state }}</div>
                <div>{{ order.delivery.pincode }}</div>
                <div *ngIf="order.billing.landmark">
                  Landmark :{{ order.delivery.landmark }}
                </div>
                <div class="mt-1">
                  Mobile: {{ order.delivery.mobile }} <small></small>
                </div>
              </div>
            </mat-card>
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex flex-column mt-3">
            <div>
              {{ order.email }} <small>*For communication purposes only</small>
            </div>
          </div>
        </div>
      </div>
    </mat-card>

    <div class="m-1">
      <mat-card class="ml-2 mr-2" *ngIf="order.books && order.books.length > 0">
        <table class="table mt-1">
          <thead>
            <tr>
              <th scope="col">SI.No</th>
              <th scope="col">Book</th>
              <th scope="col">QTY</th>
              <th scope="col">Rate</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tr *ngFor="let book of order.books; let i = index">
            <td>{{ i + 1 }}</td>
            <td>
              {{ book.id }}-{{ book.grade }} - {{ book.name }} -
              {{ book.language }}
            </td>
            <td>{{ book.orderQty }}</td>
            <td>{{ book.amount }}</td>
            <td>{{ book.orderQty * book.amount | currency: "INR" }}</td>
          </tr>

          <tr></tr>
          <tr style="font-weight: bolder">
            <td colspan="4" class="text-right mr-3">Total:</td>
            <td>{{ order.summary.total | currency: "INR" }}</td>
          </tr>
          <tr style="font-weight: bolder">
            <td colspan="4" class="text-right mr-3">Discount:</td>
            <td>{{ order.summary.discount || 0 | currency: "INR" }}</td>
          </tr>
          <tr style="font-weight: bolder">
            <td colspan="4" class="text-right mr-3">Net Total:</td>
            <td>{{ order.summary.netamount | currency: "INR" }}</td>
          </tr>
          <tr style="font-weight: bolder">
            <td colspan="4" class="text-right mr-3">Total QTY:</td>
            <td>{{ order.summary.totalqty }}</td>
          </tr>
          <tr style="font-weight: bolder">
            <td colspan="4" class="text-right mr-3">Delivery Chargers:</td>
            <td>{{ 0 | currency: "INR" }}</td>
          </tr>
          <tr style="font-weight: bolder">
            <td
              *ngIf="order.status === 'Payment Pending'"
              colspan="4"
              class="text-right mr-3"
            >
              Total Payable:
            </td>
            <td
              *ngIf="order.status === 'Payment Completed'"
              colspan="4"
              class="text-right mr-3"
            >
              Total Paid:
            </td>
            <td>{{ order.orderamount | currency: "INR" }}</td>
          </tr>
        </table>

        <div class="mt-3 text-center" *ngIf="!isEligibleForPayment()">
          <a class="ml-4" (click)="printInvoice()" color="warn" mat-flat-button
            >Print Invoice</a
          >
        </div>

        <div class="mt-3 text-center" *ngIf="isEligibleForPayment()">
          <button
            class="ml-4"
            (click)="payBtnClicked()"
            color="warn"
            mat-flat-button
          >
            Pay Order
          </button>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="mt-3 text-center mb-3">
    <button
      class="w-25"
      color="accent"
      mat-flat-button
      (click)="onCloseBtnClick()"
    >
      Close
    </button>
  </div>
</div>
