<div class="container">
  <div class="m-3 mt-10">
    <mat-toolbar class="m-0 p-0" color="accent">
      <div class="title-center">Login</div>
    </mat-toolbar>
    <mat-card class="mat-elevation-z10">
      <div class="row justify-content-center justify-item-center">
        <div class="col-12 col-md-6">
          <div class="text-center">
            <h2>Schools and Partners Login</h2>
            <img
              [src]="logo"
              style="max-height: 150px !important"
              class="rounded-circle"
            />
            <div class="m-3">
              Exclusive login for Educational institutions,School or business
              partners only.
              <div>
                <a [href]="retailLoginUrl" target="_blank">Login</a> here if you
                like to order books for your personal use
              </div>
              <div class="mt-2 primary-text">
                <strong
                  >Don't you have account with us already? Login with your email
                  address and complete the registration process</strong
                >
              </div>
            </div>
          </div>
          <firebase-ui
            (signInSuccessWithAuthResult)="successCallback($event)"
            (signInFailure)="errorCallback($event)"
            (uiShown)="uiShownCallback()"
          ></firebase-ui>
        </div>
        <div class="col-12 col-md-6">
          <img class="img-fluid" src="/assets/images/benifts.png" />
        </div>
      </div>
    </mat-card>
  </div>
</div>
