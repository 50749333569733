import { Injectable } from '@angular/core';
import { Coupon } from 'src/app/models/coupon';
import { User } from '../models/user';
import { BrandingService } from 'tt-branding-lib';
import { OrderService } from './order.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { FireStoreRef } from './firestore-ref.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  baseUrl: string;

  couponCollectionRef: AngularFirestoreCollection<Coupon>;
  constructor(
    private store: AngularFirestore,
    private branding: BrandingService
  ) {
    this.baseUrl = FireStoreRef.getFireStoreBasePath(this.branding.config);
  }

  getCouponByName(name) {
    return this.store.collection(this.baseUrl + 'coupons', (ref) =>
      ref.where('name', '==', name).limit(1)
    );
  }

  deleteCoupon(id: string) {
    return this.couponCollectionRef.doc(id).delete();
  }

  createCoupon(model: Coupon) {
    return this.couponCollectionRef.doc(model.name).set(model);
  }

  updateCoupon(model: Coupon) {
    return this.couponCollectionRef.doc(model.name).update(model);
  }

  listAllCoupons() {
    return this.couponCollectionRef;
  }

  public getDiscountRate(userOrderValue: number, total: number, user: User) {
    if (user.kyc === 'verified') return user.discount;
    else if (userOrderValue >= 10000) return 20;
    else if (userOrderValue + total >= 10000) return 15;
    else if (userOrderValue + total >= 5000) return 10;
    else return 0;
  }
}
