import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faEnvelope, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { tap } from 'rxjs/operators';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { ConfigService } from 'src/app/shared/services/config.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'app-unregistered',
  templateUrl: './unregistered.component.html',
  styleUrls: ['./unregistered.component.scss'],
})
export class UnregisteredComponent implements OnInit {
  confirmationMessage: boolean = false;
  fasRegistrationConfirm = faEnvelope;
  fasUserFailed = faUserTimes;
  showErrorMessage: boolean = false;

  steps: StepType[] = [];
  activedStep = 0;
  model: any = {};
  title: string;
  description: string;

  CONFIG_FILE_NAME = 'registration';

  form = new FormArray(this.steps.map(() => new FormGroup({})));
  options = this.steps.map(() => <FormlyFormOptions>{});

  user: any;

  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
  }

  constructor(
    private configService: ConfigService,
    private preLoader: PreLoaderService,
    private authService: FirebaseAuthService
  ) {
    this.authService.currentuser$.subscribe((data) => {
      this.user = data;
      this.configService
        .loadAppJson(this.CONFIG_FILE_NAME)
        .pipe(
          tap(({ schema, model }) => {
            this.title = schema.title;
            this.description = schema.description;
            this.steps = schema.properties.steps || [];
            this.form = new FormArray(this.steps.map(() => new FormGroup({})));
            this.options = this.steps.map(() => <FormlyFormOptions>{});
            this.model = model;
            this.model['id'] = data.uid;
            this.model['uid'] = data.uid;
            this.model['email'] = data.email;
            this.preLoader.hide();
          })
        )
        .subscribe();
    });
  }

  async submit() {
    this.preLoader.show();
    this.model['emailVerified'] = this.user?.emailVerified || false;
    this.authService.createUser(this.model).then((response: any) => {
      if (response) {
        this.confirmationMessage = true;
        this.showErrorMessage = false;
      } else {
        this.confirmationMessage = false;
        this.showErrorMessage = true;
      }
      this.preLoader.hide();
    });
  }

  ngOnInit(): void {}
}
