<div class="container p-3">
  <div class="row">
    <div class="col-12">
      <mat-card class="text-center m-1">
        <div *ngIf="showPaymentMessage">
          <div class="alert alert-danger" role="alert">
            <h2>Payment is in Progress</h2>
            <div>
              Please do not refresh or close your browser while we process your
              payments
            </div>
          </div>
        </div>

        <div class="d-flex flex-column">
          <h1>
            <span style="color: #00bcd4">Order ID: {{ order?.id }}</span>
          </h1>
        </div>
        <form #paymentForm ngNoForm action="{{ actionUrl }}" method="post">
          <div>Name: {{ order?.name }}</div>
          <div>Email: {{ order?.email }}</div>
          <div>Mobile: {{ order?.mobile }}</div>
          <div>
            <h2>
              <strong
                >Amount:
                {{ order?.summary.netamount | currency: "INR" }}</strong
              >
            </h2>
          </div>
          <div class="mb-3">
            <strong>Status: {{ order?.status }}</strong>
          </div>

          <div
            class="row justify-content-md-center"
            *ngIf="!isEligibleForPayment()"
          >
            <div class="col-12 col-md-6">
              <button
                mat-raised-button
                color="warn"
                routerLink="/my-orders"
                class="mt-3 mb-3"
                type="button"
              >
                My Orders
              </button>
            </div>
          </div>

          <app-default-payment
            [showDefaultPayment]="showDefaultPayment"
            [order]="order"
            (uploadStatusAction)="uploadStatusAction($event)"
            (paymentOptionAction)="paymentOptionAction()"
            *ngIf="showDefaultPayment"
          >
          </app-default-payment>

          <div
            class="row justify-content-md-center"
            *ngIf="isEligibleForPayment()"
          >
            <div class="col-12 col-md-4" *ngIf="!showDefaultPayment">
              <button
                class="my-1 p-0 w-100"
                mat-flat-button
                type="button"
                (click)="$event.preventDefault(); showDefaultPayment = true"
              >
                <mat-card class="m-0 p-0">
                  <button
                    class="w-100"
                    color="warn"
                    type="button"
                    mat-flat-button
                  >
                    NEFT / RTGS Payment
                  </button>
                  <div>
                    <img
                      class="img-fluid rounded-lg"
                      style="border: hidden; max-height: 60px !important"
                      src="/assets/images/rtgs-upi.png"
                    />
                  </div>
                </mat-card>
              </button>
            </div>
            <div class="col-12 col-md-4" *ngIf="!showDefaultPayment">
              <button
                class="my-1 p-0 w-100"
                mat-flat-button
                type="button"
                (click)="$event.preventDefault(); submitPayUForm()"
              >
                <mat-card class="m-0 p-0">
                  <button
                    class="w-100"
                    color="warn"
                    type="button"
                    mat-flat-button
                  >
                    Pay with PayuMoney
                  </button>
                  <div>
                    <img
                      class="img-fluid rounded-lg"
                      style="border: hidden; max-height: 60px !important"
                      src="/assets/images/payuBanrding.png"
                    />
                  </div>
                </mat-card>
              </button>
            </div>

            <div class="col-12 col-md-4" *ngIf="!showDefaultPayment">
              <button
                class="my-1 p-0 w-100"
                mat-flat-button
                type="button"
                (click)="$event.preventDefault(); submitRazorPayForm()"
              >
                <mat-card class="m=0 p-0">
                  <button
                    class="w-100"
                    color="warn"
                    type="button"
                    mat-flat-button
                  >
                    Pay with Razorpay
                  </button>
                  <div>
                    <img
                      class="img-fluid rounded-lg"
                      style="border: hidden; max-height: 60px !important"
                      src="/assets/images/razorpay-logo.svg"
                    />
                  </div>
                </mat-card>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
