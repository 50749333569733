import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreLoaderService {

  private preloaderSubject = new BehaviorSubject<boolean>(true);
  loading$ = this.preloaderSubject.asObservable();

  show() {
    this.preloaderSubject.next(true);
  }

  hide() {
    this.preloaderSubject.next(false);
  }
}
