<div class="container">
    <div class="mt-3 mb-3">
        <mat-toolbar color="accent">
            <div>Upload KYC documents</div>
        </mat-toolbar>
        <mat-card class="ml-2 mr-2">
            <div class="row">

                <div class="col-12 col-md-6 p-2">
                    <mat-form-field appearance="legacy">
                        <mat-label>Registration Document</mat-label>
                        <ngx-mat-file-input accept="image/jpeg,application/pdf" (change)="panFileChangeEvent($event)"
                            placeholder=" Select Document">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>attachment</mat-icon>
                        <mat-hint>Attach school Registration Document</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-6 p-2">
                    <mat-form-field appearance="legacy">
                        <mat-label>Pancard Document</mat-label>
                        <ngx-mat-file-input accept="image/jpeg,application/pdf" (change)="panFileChangeEvent($event)"
                            placeholder=" Select Document">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>attachment</mat-icon>
                        <mat-hint>Attach your business or school Pan Card</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-6 p-2">
                    <mat-form-field appearance="legacy">
                        <mat-label>GST Certificate</mat-label>
                        <ngx-mat-file-input accept="image/jpeg,application/pdf" (change)="GSTFileChangeEvent($event)"
                            placeholder=" Select Document">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>attachment</mat-icon>
                        <mat-hint>Attach your business GST Registration Document</mat-hint>
                    </mat-form-field>
                </div>

            </div>
        </mat-card>
    </div>
</div>