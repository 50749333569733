import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/guards/auth.guard';
import { HomeComponent } from './pages/components/home/home.component';
import { LoginComponent } from './pages/components/login/login.component';
import { ProfileComponent } from './pages/components/profile/profile.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { UnregisteredComponent } from './pages/components/unregistered/unregistered.component';
import { MyOrderComponent } from './pages/components/my-order/my-order.component';
import { OrderBookComponent } from './pages/components/order-book/order-book.component';
import { BrowseBooksComponent } from './pages/components/browse-books/browse-books.component';
import { PaymentComponent } from './pages/components/payment/payment.component';
import { UserViewOrderComponent } from './pages/components/user-view-order/user-view-order.component';
import { PaymentConfirmComponent } from './pages/components/payment-confirm/payment-confirm.component';
import { UploadKycComponent } from './pages/components/upload-kyc/upload-kyc.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'upload-kyc',
    component: UploadKycComponent,
    canActivate: [AuthGuard],
  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'books', component: BrowseBooksComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'my-orders', component: MyOrderComponent, canActivate: [AuthGuard] },
  {
    path: 'payment/:status/:id',
    component: PaymentConfirmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order/books',
    component: OrderBookComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order/view/:id',
    component: UserViewOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order/payment/:id',
    component: PaymentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'unregistered',
    component: UnregisteredComponent,
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
