import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Book } from 'src/app/models/book';
import { BrandingService } from 'tt-branding-lib';
@Injectable({
  providedIn: 'root',
})
export class BookService {
  private expirationDuration = 1000 * 60 * 60 * 12; // 12 hours

  booksLoaded: boolean;

  private _books: BehaviorSubject<Book[]> = new BehaviorSubject([]);
  private readonly books: Observable<Book[]> = this._books.asObservable();

  private readonly _bookByCategory = new BehaviorSubject<
    Map<string, Map<string, Book[]>>
  >(new Map());
  readonly bookByCategory$ = this._bookByCategory.asObservable();
  private get bookByCategory(): Map<string, Map<string, Book[]>> {
    return this._bookByCategory.getValue();
  }

  constructor(
    private httpClient: HttpClient,
    private branding: BrandingService
  ) {}
  groupByKey(list, key) {
    let resultObject = list.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    return new Map<string, Book[]>(Object.entries(resultObject));
  }

  getBooks() {
    if (localStorage.getItem('partners-books')) {
      let localBooks = JSON.parse(localStorage.getItem('partners-books'));
      const currentTime = new Date().getTime();
      const prevAccepted = localBooks.dataTime;
      const notAccepted = prevAccepted == undefined;
      const prevAcceptedExpired =
        prevAccepted != undefined &&
        currentTime - prevAccepted > this.expirationDuration;
      if (notAccepted || prevAcceptedExpired) {
        this.loadBooks();
      } else {
        let booksMap = new Map<string, Map<string, Book[]>>();
        this._books.next(localBooks.data);
        booksMap.set('category', this.groupByKey(localBooks.data, 'category'));
        booksMap.set('language', this.groupByKey(localBooks.data, 'language'));
        booksMap.set('grade', this.groupByKey(localBooks.data, 'grade'));
        this._bookByCategory.next(booksMap);
      }
    } else {
      this.loadBooks();
    }

    return this.books;
  }

  loadBooks() {
    var config = this.branding.config.api;
    var endpoint = config.baseUrl + config.product?.list;
    this.httpClient
      .get(endpoint)
      .toPromise()
      .then((data: Book[]) => {
        let booksMap = new Map<string, Map<string, Book[]>>();
        this._books.next(data);
        booksMap.set('category', this.groupByKey(data, 'category'));
        booksMap.set('language', this.groupByKey(data, 'language'));
        booksMap.set('grade', this.groupByKey(data, 'grade'));
        this._bookByCategory.next(booksMap);
        localStorage.setItem(
          'partners-books',
          JSON.stringify({
            expire: this.expirationDuration,
            dataTime: new Date().getTime(),
            data,
          })
        );
      });
  }

  getAllBookBySectionAndCategory(sectionId: string, categoryId: string) {
    if (!this.bookByCategory.get(sectionId).has(categoryId)) {
      console.log('book by this category not found' + categoryId);
    }
  }
  async getBookById(id: string) {
    return await this._books.getValue().find((x) => x.id === id);
  }
  calulatePercentage(book: Book): any {
    let amount = book.amount;
    let discount = book.discount;
    let newRate: number = amount - discount;
    return Math.round(((amount - newRate) / amount) * 100);
  }
}
