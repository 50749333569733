import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) {
  }

  loadMenuJson() {
    return this.http.get<any>(`assets/json/menus.json`);
  }

  loadAppJson(name: string) {
    return this.http.get<any>(`assets/json/app/${name}.json`);
  }
  loadJson(name: string) {
    return this.http.get<any>(`assets/json/${name}.json`);
  }
}
