import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { Order } from 'src/app/models/order';
import { User } from '../models/user';
import { BrandingService } from 'tt-branding-lib';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { FireStoreRef } from './firestore-ref.service';
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl: string;

  private orderCollectionRef: AngularFirestoreCollection<Order>;
  private _orders = new BehaviorSubject<any[]>([]);
  public readonly orders = this._orders.asObservable();
  private user: any;

  constructor(
    private store: AngularFirestore,
    private afStorage: AngularFireStorage,
    private branding: BrandingService,
    private authService: FirebaseAuthService
  ) {
    this.baseUrl = FireStoreRef.getFireStoreBasePath(this.branding.config);
    this.authService.currentuser$.subscribe((data) => {
      this.user = data;
      this.orderCollectionRef = this.store.collection(this.baseUrl + '/orders');
    });
  }

  public getUserOrder() {
    this.store
      .collection(this.baseUrl + '/orders', (ref) =>
        ref.where('uid', '==', this.user.uid)
      )
      .valueChanges()
      .subscribe((data) => {
        this._orders.next(data);
      });
  }

  public updateOrder(order: any) {
    return this.orderCollectionRef.doc(order.id).set(order);
  }

  async uploadImageAsPromise(imageFile: File, filePath: string) {
    const ref = this.afStorage.ref(filePath);
    return new Promise(function (resolve, reject) {
      var task: AngularFireUploadTask = ref.put(imageFile);
      task.task.on(
        'state_changed',
        function progress(snapshot) {},
        function error(err) {
          reject(err);
        },
        function complete() {
          resolve(task);
        }
      );
    })
      .then(function (task: AngularFireUploadTask) {
        return task.task.snapshot.ref.getDownloadURL();
      })
      .then(function (url) {
        return url;
      });
  }

  public async createOrder(order: Order) {
    let id: string = this.store.createId();
    let value = { id, ...order };
    await this.orderCollectionRef.doc(id).set(value);
    return id;
  }

  getOrderByIdAndUid(uid: string, id: string) {
    return this.store.collection(this.baseUrl + '/orders', (ref) =>
      ref.where('id', '==', id).where('uid', '==', uid).limit(1)
    );
  }

  getOrderById(id: string) {
    return this.store.collection(this.baseUrl + '/orders', (ref) =>
      ref.where('id', '==', id).limit(1)
    );
  }
}
