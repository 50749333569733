import { DatePipe } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { Book } from 'src/app/models/book';
import { Order } from 'src/app/models/order';
import { BookService } from 'src/app/shared/services/book.service';
import { CouponService } from 'src/app/shared/services/coupon.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

@Component({
  selector: 'app-order-book',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.scss'],
})
export class OrderBookComponent implements AfterContentInit {
  books: any[] = [];
  private rawbooks: any[] = [];
  categories: string[];
  languages: string[];
  grades: string[];

  booksInCart: any[] = [];
  templResult = new Map();
  summary: any = {};
  reviewPage = false;
  order: Order;
  user: any;
  panelOpenState = false;

  @ViewChild('deliveryForm') deliveryForm: NgForm;

  private isOrderPaidByStatus(status: string) {
    return [
      'Payment Completed',
      'Payment Received',
      'Order Dispacted',
    ].includes(status);
  }

  setOrderSummary() {
    if (this.booksInCart.length > 0) {
      let total = this.booksInCart
        .map((x) => x.orderQty * x.amount)
        .reduce((accum, current) => accum + current, 0);

      let userOrderValue = 0;
      this.orderService.orders.subscribe((orders) => {
        userOrderValue = orders
          .filter((x) => this.isOrderPaidByStatus(x.status))
          .map((y) => y.orderamount)
          .reduce((accumulator, curr) => accumulator + curr, 0);
      });

      let discount =
        total *
        (this.couponService.getDiscountRate(userOrderValue, total, this.user) *
          0.01);
      let totalqty = this.booksInCart
        .map((x) => (x.orderQty ? Number.parseInt(x.orderQty) : 0))
        .reduce((accum, current) => accum + current, 0);
      this.summary = {
        total: total,
        totalqty: totalqty,
        discount: discount,
        netamount: discount > 0 ? total - discount : total,
      };
    } else {
      this.summary = {};
    }
  }

  reviewBtnClicked() {
    this.reviewPage = true;
    this.order = { ...this.getOrderInformation() };
    this.order['books'] = [...this.booksInCart];
    this.order['summary'] = this.summary;
    this.order.createdDate = this.datepipe.transform(new Date(), 'medium');
    this.order.status = 'Payment Pending';
    this.order.orderamount = this.order.summary.netamount;
  }

  getOrderInformation(): any {
    let user = this.user;
    return {
      uid: user.uid,
      name: user.name,
      email: user.email,
      mobile: user.mobile,
      billing: {
        address1: user.address1 || '',
        address2: user.address2 || '',
        city: user.city || '',
        district: user.district || '',
        state: user.state || '',
        pincode: user.pincode || '',
        landmark: user.landmark || '',
        mobile: user.mobile || '',
      },
      delivery: {
        name: user.name || '',
        address1: user.address1 || '',
        address2: user.address2 || '',
        city: user.city || '',
        district: user.district || '',
        state: user.state || '',
        pincode: user.pincode || '',
        landmark: user.landmark || '',
        mobile: user.mobile || '',
      },
    };
  }

  onAddressChange(event) {
    this.panelOpenState = event.checked;
    if (!event.checked) {
      this.order['delivery'] = this.getOrderInformation().delivery;
    } else {
      this.order.delivery = {
        name: '',
        address1: '',
        address2: '',
        city: '',
        district: '',
        state: '',
        pincode: '',
        landmark: '',
        mobile: '',
      };
    }
  }

  updateOrderBtnClicked() {
    this.reviewPage = false;
  }
  payBtnClicked() {
    this.orderService.createOrder(this.order).then((id) => {
      this.router.navigateByUrl('/order/payment/' + id);
    });
  }

  onDeleteBook(bookid) {
    let book = this.rawbooks.find((x) => x.id == bookid);
    this.templResult.delete(book.id);
    this.booksInCart = [...this.templResult.values()];
    this.setOrderSummary();
  }

  onQtyChange(value, bookid) {
    let book = this.rawbooks.find((x) => x.id == bookid);
    if (value && value <= 0) {
      this.templResult.delete(book.id);
    } else {
      book['orderQty'] = value;
      this.templResult.set(book.id, book);
    }
    this.booksInCart = [...this.templResult.values()];
    this.setOrderSummary();
  }

  updateDisplay(books: Book[]) {
    this.books = books;
  }

  constructor(
    private authService: FirebaseAuthService,
    private router: Router,
    private preLoaderService: PreLoaderService,
    private bookService: BookService,
    private datepipe: DatePipe,
    private couponService: CouponService,
    private orderService: OrderService
  ) {}

  ngAfterContentInit(): void {
    this.preLoaderService.show();
    this.authService.currentuser$.subscribe((data) => (this.user = data));
    this.bookService.getBooks().subscribe((booksData: any[]) => {
      this.rawbooks = booksData;
      this.preLoaderService.hide();
    });
  }
}
