import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { Order } from 'src/app/models/order';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-default-payment',
  templateUrl: './default-payment.component.html',
  styleUrls: ['./default-payment.component.scss'],
})
export class DefaultPaymentComponent implements OnInit {
  @Input()
  order: Order;

  @Input()
  showDefaultPayment: boolean = false;

  @Output()
  paymentOptionAction = new EventEmitter<any>();

  @Output()
  uploadStatusAction = new EventEmitter<any>();

  screenshot: any;
  showFileErrorMessage: boolean = false;
  screenShotFile: File;
  uploadInprogress: boolean;
  paymentFormGroup: FormGroup;
  maxSize: number = 52428800;

  changePaymentOption() {
    this.paymentOptionAction.emit();
  }

  ngOnInit(): void {
    this.paymentFormGroup = this._formBuilder.group({
      paymentscreen: [
        undefined,
        [Validators.required, FileValidator.maxContentSize(this.maxSize)],
      ],
    });
  }

  get paymentscreen() {
    return this.paymentFormGroup.get('paymentscreen');
  }

  constructor(
    private _formBuilder: FormBuilder,
    private orderService: OrderService,
    private router: Router
  ) {}

  fileChangeEvent(event: any): void {
    this.showFileErrorMessage = false;
    let tempFile: File =
      event.target && event.target.files && event.target.files[0];
    if (tempFile == null) {
      this.showFileErrorMessage = true;
      this.paymentFormGroup.get('paymentscreen')?.setValue('');
      return;
    }
    if (tempFile.type.match(/image\/*/) == null) {
      return;
    }

    this.screenShotFile = tempFile;
    var reader = new FileReader();
    reader.readAsDataURL(tempFile);
    reader.onload = (_event) => {
      this.screenshot = reader.result;
    };
  }

  uploadScreenShot() {
    this.screenshot &&
      this.uploadScreenShotImage().then((url) => {
        this.uploadStatusAction.emit(url);
      });
  }

  async uploadScreenShotImage() {
    let paymentscreenshotUrl;
    this.uploadInprogress = true;
    let imageName = 'image_' + Math.random().toString(36).substring(2);
    const imageBlob = this.dataURItoBlob(this.screenshot.split(',')[1]);
    const imageFile = new File([imageBlob], imageName, {
      type: 'image/jpeg',
    });
    const filePath = '/order/' + this.order.id + '/' + imageName;
    paymentscreenshotUrl = await this.orderService.uploadImageAsPromise(
      imageFile,
      filePath
    );
    this.uploadInprogress = false;
    return paymentscreenshotUrl;
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
}
