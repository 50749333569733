import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { CommonUtil } from './shared/common.util';
import { User } from './shared/models/user';
import { PreLoaderService } from './shared/services/pre-loader.service';
import { BrandingService } from 'tt-branding-lib';
import { FirebaseAuthService } from 'tt-authentication-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title: string;
  appname: string;
  user: any;
  logo;
  subtitle;

  @ViewChild('sidenav') public sidenav: MatSidenav;
  isHandset$: Observable<boolean>;

  config: any;
  constructor(
    commonUtil: CommonUtil,
    private titleService: Title,
    private preloader: PreLoaderService,
    private authService: FirebaseAuthService,
    private brandingConfig: BrandingService
  ) {
    this.isHandset$ = commonUtil.isHandset$;
    this.brandingConfig.config$.subscribe((data) => (this.config = data));
  }

  closeSideNav() {
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  onLogout(user: User) {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.preloader.show();
    this.logo = this.config?.app.logo;
    this.titleService.setTitle(this.config?.app?.title);
    this.title = this.config?.app?.title;
    this.subtitle = this.config?.app?.subtitle;
    this.titleService.setTitle(this.title);
    this.authService.currentuser$.subscribe((data) => {
      this.user = data;
      this.preloader.hide();
    });
  }
}
