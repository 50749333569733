<div class="container-fluid">
  <app-filter-book
    [books]="rawbooks"
    [earlyloading]="false"
    (filterEvent)="updateDisplay($event)"
  ></app-filter-book>
  <div class="row">
    <div class="col-12">
      <cdk-virtual-scroll-viewport
        class="books-virtual-scroll-viewport"
        itemSize="100"
      >
        <div class="row">
          <ng-container *ngFor="let book of books">
            <div class="col-12 col-md-4 col-lg-2">
              <mat-card class="m-2 text-center">
                <div>
                  <img
                    *ngIf="book?.images?.length > 0"
                    class="img-fluid rounded-lg"
                    [src]="book?.images[0]"
                  />
                </div>
                <div>
                  <strong>{{ book.name }}</strong>
                  <div>{{ book.category }}</div>
                  <div>{{ book.subcategory }}</div>
                  <div>
                    <h2>{{ book.price | currency: "INR" }}</h2>
                  </div>

                  <!--
                  <div *ngIf="book.stock">
                    <div>
                      <span>In Stock</span>
                      <mat-icon style="color: green; font-size: 18px !important"
                        >check
                      </mat-icon>
                    </div>
                  </div>
                  <div *ngIf="!book.stock">
                    <div>
                      <span>Out of Stock</span>
                      <mat-icon style="color: red; font-size: 18px !important"
                        >clear
                      </mat-icon>
                    </div>
                  </div>
                  -->
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
