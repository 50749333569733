import { Injectable } from '@angular/core';
import { switchMap, catchError, take } from 'rxjs/operators';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  user: any;
  returnUrl: string;

  constructor(
    private router: Router,
    private authService: FirebaseAuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user) => {
        if (user?.uid) {
          if (Boolean(user?.unregistered)) {
            this.router.navigateByUrl('/unregistered');
            return of(true);
          }
          if (Boolean(user?.authorized)) {
            return of(true);
          } else {
            return of(false);
          }
        } else {
          this.router.navigate(['login'], {
            queryParams: { returnUrl: state.url },
          });
          return of(false);
        }
      }),
      catchError(() => {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
