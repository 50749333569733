<app-preloader style="z-index: 100"></app-preloader>
<div class="container-fluid p-0 app-container">
  <div class="header">
    <ng-container *ngIf="!(isHandset$ | async)">
      <mat-toolbar class="header" color="primary">
        <div class="mx-1">
          <a routerLink="/">
            <img class="rounded-circle" style="max-width: 50px" [src]="logo" />
          </a>
        </div>
        <div>
          <div class="headline">
            {{ title }}
          </div>
        </div>
        <div class="spacer"></div>
        <ng-container *ngIf="!user?.uid; else logoutview">
          <button routerLink="login" mat-flat-button color="accent">
            <mat-icon>login</mat-icon> <span>Login</span>
          </button>
        </ng-container>
        <ng-template #logoutview>
          <button (click)="onLogout()" mat-flat-button color="accent">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
        </ng-template>
      </mat-toolbar>
    </ng-container>

    <ng-container *ngIf="isHandset$ | async">
      <mat-toolbar class="header" color="primary">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="ml-2">
          <span>
            {{ title }}
          </span>
        </div>
        <span class="spacer"></span>
        <ng-container *ngIf="!user?.uid; else logoutview">
          <button routerLink="login" mat-flat-button color="accent">
            <mat-icon>login</mat-icon>
          </button>
        </ng-container>
        <ng-template #logoutview>
          <button (click)="onLogout()" mat-mini-fab color="accent">
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </ng-template>
      </mat-toolbar>
    </ng-container>
  </div>

  <div class="menubar" *ngIf="!(isHandset$ | async)">
    <div *ngIf="user?.uid">
      <app-menus
        [user]="user"
        (logout)="onLogout($event)"
        (closeSideNav)="closeSideNav()"
      ></app-menus>
    </div>
  </div>

  <div class="content">
    <mat-sidenav-container class="h-100" *ngIf="isHandset$ | async">
      <mat-sidenav
        #sidenav
        fixedTopGap="60"
        fixedInViewport="true"
        mode="(isHandset$ | async) ? 'over' : 'side'"
        style="width: 15em"
      >
        <div *ngIf="user?.uid">
          <app-menus
            [user]="user"
            (logout)="onLogout($event)"
            (closeSideNav)="closeSideNav()"
          ></app-menus>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="min-height: 100%">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-container *ngIf="!(isHandset$ | async)">
      <router-outlet></router-outlet>
    </ng-container>
  </div>

  <!-- Footer-->
  <div class="footer bg-primary-light">
    <tt-footer></tt-footer>
  </div>
</div>
