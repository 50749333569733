import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FireStoreRef {
  static getFireStoreBasePath(config: any) {
    return config.app.path;
  }
}
