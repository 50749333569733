import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { Order } from 'src/app/models/order';
import { CommonUtil } from 'src/app/shared/common.util';
import { User } from 'src/app/shared/models/user';
import { OrderService } from 'src/app/shared/services/order.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';
import { WindowRefService } from 'src/app/shared/services/window-ref.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  order: Order;
  user: any;
  orderId: string;
  showPaymentMessage: boolean = false;
  showDefaultPayment: boolean = false;
  actionUrl: string;
  config: any;

  @ViewChild('paymentForm')
  paymentFormElement: ElementRef;

  async ngOnInit(): Promise<void> {}

  constructor(
    private winRef: WindowRefService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private authService: FirebaseAuthService,
    private preLoader: PreLoaderService,
    private paymentService: PaymentService,
    private commonUtil: CommonUtil,
    private router: Router,
    private branding: BrandingService
  ) {
    this.config = this.branding.config;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let currentRoute = this.route;
        this.orderId = currentRoute.snapshot.paramMap.get('id');
        this.authService.currentuser$.subscribe((data) => {
          this.user = data;
          this.getOrderById(this.user.uid, this.orderId);
        });
      }
    });
  }
  getOrderById(uid: string, orderId: string) {
    this.orderService
      .getOrderByIdAndUid(uid, orderId)
      .valueChanges()
      .subscribe((orderData: Order[]) => {
        if (orderData && orderData[0]) {
          this.order = orderData[0];
        }
      });
  }

  paymentOptionAction() {
    this.showDefaultPayment = false;
  }

  uploadStatusAction(url: string) {
    this.order.paymentUrl = url;
    this.order.status = 'Payment Completed';
    this.order['paymentInfo'] = {
      status: 'Payment Completed' || '',
      mode: 'NEFT/RTGS/UPI',
      bankTxnId: 'Online' || '',
      txnDate: new Date().toDateString() || '',
    };
    this.orderService.updateOrder(this.order).then(() => {
      this.router.navigateByUrl('/my-orders');
    });
  }

  payWithRazor(paymentInfo) {
    let tempOrderInfo = JSON.parse(paymentInfo);
    const options: any = {
      key: tempOrderInfo.key,
      amount: this.order.orderamount * 100,
      currency: 'INR',
      name: this.branding.config.app.title,
      description: this.order.id,
      image: '/assets/sptag.jpg',
      callback_url: this.config.apiUrl + this.config.api.payment.razorcallback,
      order_id: tempOrderInfo.id,
      prefill: {
        name: this.order.name,
        email: this.order.email,
        contact: this.order.mobile,
      },
      modal: {
        escape: false,
      },
      notes: {
        oid: this.order.id,
        name: this.branding.config.app.title,
      },
      theme: {
        color: '#00bcd4',
      },
    };
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  submitRazorPayForm() {
    this.setPaymentProgress();
    this.paymentService
      .initiateRazorPayment(this.order.id)
      .then((data) => this.payWithRazor(data));
  }

  isEligibleForPayment() {
    return this.commonUtil.isEligibleForPayment(this.order);
  }

  submitPayUForm() {
    this.setPaymentProgress();
    let apiUrl = this.config.api.payment;
    this.actionUrl =
      this.config.apiUrl + apiUrl.payuinit + 'oid=' + this.order.id;
    this.paymentFormElement.nativeElement.action = this.actionUrl;
    this.paymentFormElement.nativeElement.submit();
  }
  submitPaytmForm() {
    this.setPaymentProgress();
    let apiUrl = this.config.api.payment;
    this.actionUrl = apiUrl + '/paytm/init?oid=' + this.order.id;
    this.paymentFormElement.nativeElement.action = this.actionUrl;
    this.paymentFormElement.nativeElement.submit();
  }

  setPaymentProgress() {
    this.showPaymentMessage = true;
    this.preLoader.show();
  }
}
