<mat-nav-list [ngClass]="{ 'list-horizontal': !(isHandset$ | async) }">
  <ng-container *ngIf="!user?.uid && (isHandset$ | async)">
    <mat-list-item
      routerLink="/"
      (click)="closeSidebar()"
      routerLinkActive="active"
    >
      <mat-icon>login</mat-icon>
      <span class="ml-2">Login</span>
    </mat-list-item>
  </ng-container>

  <ng-container *ngIf="user?.uid && user?.authorized">
    <div class="text-center" *ngIf="isHandset$ | async">
      <img
        class="mt-3 mb-3 rounded-circle"
        style="max-width: 150px"
        [src]="logo"
      />
    </div>

    <mat-list-item
      routerLink="/"
      routerLinkActive="active"
      (click)="closeSidebar()"
    >
      <mat-icon>home</mat-icon>
      <span class="ml-2">Home</span>
    </mat-list-item>

    <ng-container *ngIf="user?.uid">
      <mat-list-item
        routerLink="/books"
        routerLinkActive="active"
        (click)="closeSidebar()"
      >
        <mat-icon>library_books</mat-icon>
        <span class="ml-2">Browse Catalogue </span>
      </mat-list-item>

      <mat-list-item
        routerLink="/order/books"
        routerLinkActive="active"
        (click)="closeSidebar()"
      >
        <mat-icon>shop</mat-icon>
        <span class="ml-2">Order Books</span>
      </mat-list-item>

      <mat-list-item
        routerLink="/my-orders"
        routerLinkActive="active"
        (click)="closeSidebar()"
      >
        <mat-icon>list_alt</mat-icon>
        <span class="ml-2">My Orders</span>
      </mat-list-item>
    </ng-container>

    <mat-divider *ngIf="isHandset$ | async"></mat-divider>
    <mat-list-item
      routerLinkActive="active"
      routerLink="/profile"
      (click)="closeSidebar()"
      routerLinkActive="active"
    >
      <mat-icon>account_circle</mat-icon>
      <span class="ml-2">Profile</span>
    </mat-list-item>
    <mat-list-item
      routerLink="/support"
      (click)="closeSidebar()"
      routerLinkActive="active"
    >
      <mat-icon>assistant</mat-icon>
      <span class="ml-2">Support</span>
    </mat-list-item>
  </ng-container>

  <ng-container *ngIf="user?.uid && (isHandset$ | async)">
    <mat-list-item (click)="logoutBtnClicked()" routerLinkActive="active">
      <mat-icon>logout</mat-icon>
      <span class="ml-2">Logout</span>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
