import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import {
  faCheckCircle,
  faCheckSquare,
  faPrayingHands,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { Order } from 'src/app/models/order';
import { User } from 'src/app/shared/models/user';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss'],
})
export class PaymentConfirmComponent implements OnInit {
  orderId: string;
  status: string;
  user: any;
  order: Order;

  faSuccess = faCheckCircle;
  faFailed = faWindowClose;

  constructor(
    private route: ActivatedRoute,
    private authService: FirebaseAuthService,
    private orderService: OrderService,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let currentRoute = this.route;
        this.orderId = currentRoute.snapshot.paramMap.get('id');
        this.status = currentRoute.snapshot.paramMap.get('status');
        this.authService.currentuser$.subscribe((data) => {
          this.user = data;
          this.getOrderById(this.user.uid, this.orderId);
        });
      }
    });
  }

  redirectPaymentLink() {
    this.router.navigateByUrl('/order/payment/' + this.order.id);
  }

  getOrderById(uid: string, orderId: string) {
    this.orderService
      .getOrderByIdAndUid(uid, orderId)
      .valueChanges()
      .subscribe((orderData: Order[]) => {
        if (orderData && orderData[0]) {
          this.order = orderData[0];
        }
      });
  }

  ngOnInit(): void {}
}
