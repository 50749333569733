import { Component, OnInit } from '@angular/core';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { AdminService } from 'src/app/shared/services/admin.service';
import { BrandingService } from 'tt-branding-lib';
import { OrderService } from 'src/app/shared/services/order.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user: any;
  logo: any;
  discounts: any[];
  maxDiscount: number;
  constructor(
    private authuser: FirebaseAuthService,
    private branding: BrandingService,
    private preloader: PreLoaderService
  ) {}

  ngOnInit(): void {
    this.preloader.show();
    this.authuser.currentuser$.subscribe((user) => {
      this.user = user;
      this.preloader.hide();
    });
    this.logo = this.branding.config.app.title;
    this.discounts = this.branding.config?.discount?.slab;
    this.maxDiscount = this.branding.config?.discount?.max;
  }
}
