import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { Order } from 'src/app/models/order';
import { User } from 'src/app/shared/models/user';
import { OrderService } from 'src/app/shared/services/order.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

@Component({
  selector: 'app-user-view-order',
  templateUrl: './user-view-order.component.html',
  styleUrls: ['./user-view-order.component.scss'],
})
export class UserViewOrderComponent {
  orderId: BehaviorSubject<string> = new BehaviorSubject('');
  order: Order;
  user: any;
  mode = 'user';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private preloader: PreLoaderService,
    private cdRef: ChangeDetectorRef,
    private orderService: OrderService,
    private authService: FirebaseAuthService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.preloader.show();
        let id = this.route.snapshot.paramMap.get('id');
        if (id) this.orderId.next(id);

        this.authService.currentuser$.subscribe((userData) => {
          this.user = userData;
          this.orderId.subscribe((data) => {
            this.orderService
              .getOrderByIdAndUid(this.user.uid, data)
              .valueChanges()
              .subscribe((orderData: Order[]) => {
                if (data && data.length > 0) {
                  this.order = orderData[0];
                  this.preloader.hide();
                }
              });
          });
        });
      }
    });
  }

  closeAction() {
    if (this.mode === 'admin') this.router.navigateByUrl('/order/review');
    else this.router.navigateByUrl('/my-orders');
  }

  payBtnClicked() {
    this.router.navigateByUrl('/order/payment/' + this.order.id);
  }
}
