import { Component, Input, OnInit } from '@angular/core';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { User } from 'src/app/shared/models/user';
import { BrandingService } from 'tt-branding-lib';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input()
  mode: string = 'full';
  user: any;
  merchantTax: any;
  constructor(
    private brandingConfig: BrandingService,
    private preloader: PreLoaderService,
    private authService: FirebaseAuthService
  ) {}

  getDate(date) {
    return Date.parse(date);
  }

  ngOnInit(): void {
    this.preloader.show();
    this.merchantTax = this.brandingConfig.config.tax;
    this.authService.currentuser$.subscribe((data) => {
      this.user = data;
      this.preloader.hide();
    });
  }
}
