import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Book } from 'src/app/models/book';
import { PreLoaderService } from '../../services/pre-loader.service';

@Component({
  selector: 'app-filter-book',
  templateUrl: './filter-book.component.html',
  styleUrls: ['./filter-book.component.scss'],
})
export class FilterBookComponent implements OnChanges {
  @Input()
  books: Book[];

  @Input()
  earlyloading: boolean;

  @Output()
  filterEvent = new EventEmitter<Book[]>();

  categories: string[];
  subcategories: string[];

  resultCount = 0;

  constructor(private preLoaderService: PreLoaderService) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.preLoaderService.show();
    if (changes && changes.books && changes.books.currentValue) {
      this.books = JSON.parse(JSON.stringify(changes.books.currentValue));
      this.categories = this.books
        .map((x) => x.category)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
    }

    this.updateDisplay();
  }

  isEligibleToDisplay() {
    return (
      this.subCategorySelection.length > 0 || this.categorySelection.length > 0
    );
  }

  updateDisplay() {
    this.preLoaderService.show();
    let result = this.books;
    if (result) {
      if (this.categorySelection && this.categorySelection.length > 0) {
        result = result.filter((x) =>
          this.categorySelection.includes(x.category)
        );
      }
      this.subcategories = result
        .map((x: any) => x.subcategory)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();

      if (this.categorySelection && this.categorySelection.length > 0) {
        if (this.subCategorySelection && this.subCategorySelection.length > 0)
          result = result.filter((x: any) =>
            this.subCategorySelection.includes(x.subcategory)
          );
      }
    }
    this.resultCount = result?.length;
    this.preLoaderService.hide();
    this.filterEvent.emit(result);
  }

  subCategorySelection: string[] = [];
  onSubCategorySelection(v: any[]) {
    this.subCategorySelection = v;
    this.updateDisplay();
  }

  categorySelection: string[] = [];
  onCategorySelection(v: any[]) {
    this.categorySelection = v;
    this.subCategorySelection = [];
    this.updateDisplay();
  }
  clearFilter() {
    this.subCategorySelection = [];
    this.categorySelection = [];
    this.updateDisplay();
  }
}
