<div class="container-fulid">
    <div>
        <ng-container *ngIf="orders.length > 0; else firsttimeoffer">
            <app-data-table mode (viewAction)="displayOrderInfo($event)" [data]="orders" [config]="tableConfig">
            </app-data-table>
        </ng-container>
        <ng-template #firsttimeoffer>
            <div class="container">
                <div class="m-3">
                    <mat-toolbar color="accent" *ngIf="mode!=='mini'">
                        <div>No order found</div>
                    </mat-toolbar>

                    <!-- THis is only for My order with Mini View-->
                    <div class="text-center" *ngIf="mode=='mini'">
                        <div>
                            <h3>Your order history is empty!</h3>
                        </div>
                        <div>
                            <button routerLink="/order/books" routerLinkActive="active" mat-flat-button color="primary">
                                Order Books Now
                            </button>
                        </div>
                    </div>

                    <mat-card *ngIf="mode!=='mini'" style="min-height: 300px !important" class="text-center">
                        <div class="d-flex flex-column">
                            <h2>Your order history is empty!</h2>
                            <div>
                                <p>Few more steps required to share the quality books to kids </p>
                                <div class="text-center">
                                    <mat-icon style="font-size: 100px !important; height: auto; width: auto">
                                        local_shipping
                                    </mat-icon>
                                </div>
                                <button class="m-5" routerLink="/books" routerLinkActive="active" mat-flat-button
                                    color="primary">
                                    Add Books
                                </button>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </ng-template>
    </div>
</div>