import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Order } from 'src/app/models/order';
import { CommonUtil } from 'src/app/shared/common.util';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.scss'],
})
export class MyOrderComponent implements OnInit {
  @Input()
  mode: string = 'full';

  orders: Order[] = [];
  tableConfig = {
    export: true,
    format: {
      orderamount: 'curreny',
    },
    mini: ['orderamount', 'createdDate', 'status'],
    display: ['id', 'orderamount', 'createdDate', 'status'],
  };

  isHandset$: Observable<boolean>;

  displayOrderInfo(data) {
    this.router.navigateByUrl('/order/view/' + data.id);
  }

  constructor(
    private orderService: OrderService,
    private router: Router,
    private commonUtil: CommonUtil
  ) {
    this.isHandset$ = commonUtil.isHandset$;
    this.orderService.getUserOrder();
  }
  ngOnInit(): void {
    this.isHandset$.subscribe((data) => {
      this.tableConfig['mode'] =
        this.mode === 'mini' ? 'mini' : data ? 'gallery' : 'list';
    });
    this.orderService.getUserOrder();
    this.orderService.orders.subscribe((data) => {
      const orders = data.sort((a: Order, b: Order) => {
        return (
          a.createdDate &&
          b.createdDate &&
          this.commonUtil.getOrderDate(b.createdDate) -
            this.commonUtil.getOrderDate(a.createdDate)
        );
      });
      this.orders = orders;
    });
  }
}
