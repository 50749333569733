<div class="container-fluid">
  <div class="row" *ngIf="config.mode!='mini'">
    <div class="col-12 col-md-9">
      <div *ngIf="dataSource">
        <mat-form-field class="w-100">
          <mat-label>Custom Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input />
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-end align-items-end text-center">
        <div class="d-flex flex-column m-1" *ngIf="checkDisplay('refresh')">
          <button class="m-0 p-0" (click)="refreshAction()" mat-icon-button>
            <mat-icon>refresh</mat-icon>
          </button>
          <small class="mat-icon-btn-text">Refresh</small>
        </div>

        <div class="d-flex flex-column m-1" *ngIf="checkDisplay('export')">
          <button class="m-0 p-0" (click)="exportAction()" mat-icon-button>
            <mat-icon>arrow_circle_up</mat-icon>
          </button>
          <small class="mat-icon-btn-text">Export</small>
        </div>

        <div class="d-flex flex-column m-1" *ngIf="checkDisplay('import')">
          <button class="m-0 p-0" (click)="importActionBtnClick()" mat-icon-button>
            <mat-icon>arrow_circle_down</mat-icon>
          </button>
          <small class="mat-icon-btn-text">Import</small>
        </div>

        <div class="d-flex flex-column m-1" *ngIf="checkDisplay('new')">
          <button class="m-0 p-0" (click)="addNewAction()" mat-icon-button>
            <mat-icon>add_box</mat-icon>
          </button>
          <small class="mat-icon-btn-text">New</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div *ngIf="config.mode ==='list'||config.mode ==='mini'">
        <div *ngIf="displayedColumns; else infoBlock">
          <table mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of displayedColumns">
              <ng-container [matColumnDef]="column" *ngIf="column === 'actions'; else other_content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let rowData">
                  <button *ngIf="checkDisplay('edit')" mat-icon-button color="primary"
                    (click)="editBtnClickAction(rowData)">
                    <mat-icon>mode_edit</mat-icon>
                  </button>
                  <button *ngIf="checkDisplay('delete')" mat-icon-button color="warn"
                    (click)="deleteBtnClickAction(rowData)">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button *ngIf="checkDisplay('view')" mat-icon-button color="accent"
                    (click)="viewBtnClickAction(rowData)">
                    <mat-icon>visibility</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-template #other_content>
                <ng-container [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ column | uppercase }}
                  </th>
                  <ng-container
                    *ngIf="config && config.format && config.format[column] === 'curreny' ; else noformatter">
                    <td mat-cell *matCellDef="let rowData">{{ rowData[column] |currency: "INR"}}</td>
                  </ng-container>
                  <ng-template #noformatter>
                    <td mat-cell *matCellDef="let rowData">{{ rowData[column] }}</td>
                  </ng-template>
                </ng-container>
              </ng-template>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator *ngIf="config.mode !=='mini'" [pageSizeOptions]="[10,25, 40, 60, 100]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>

      <div *ngIf="config.mode ==='gallery'">
        <div class="row">
          <ng-container *ngFor="let rowData of galleryData">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="p-2">
                <mat-card class="mat-elevation-z12">
                  <div class="m-2">
                    <div *ngIf="config?.title">
                      <strong>{{rowData[config.title]}}</strong>
                    </div>
                    <ng-container *ngFor="let column of displayedColumns">
                      <ng-container *ngIf="column !== 'actions'">
                        <div class="row" *ngIf="config?.title !== column">
                          <div class="col-4" *ngIf="!config?.labeloff">
                            {{column | titlecase}}
                          </div>
                          <div class="col-8">
                            <ng-container
                              *ngIf="config && config.format && config.format[column] === 'curreny' ; else noformatter">
                              {{rowData[column] |currency: "INR"}}
                            </ng-container>
                            <ng-template #noformatter>
                              {{rowData[column]}}
                            </ng-template>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <mat-card-actions>
                      <button *ngIf="checkDisplay('view')" color="primary" mat-icon-button
                        (click)="viewBtnClickAction(rowData)">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button *ngIf="checkDisplay('edit')" color="primary" mat-icon-button
                        (click)="editBtnClickAction(rowData)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button *ngIf="checkDisplay('delete')" color="warn" mat-icon-button
                        (click)="deleteBtnClickAction(rowData)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-card-actions>
                  </div>
                </mat-card>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #infoBlock>
  <div class="row message d-flex jutify-content-center my-4 m-3">
    <div class="text-center">
      <span>No data available</span>
    </div>
  </div>
</ng-template>

<ng-template #import>

  <mat-toolbar color="primary">
    <div>
      <h1 mat-dialog-title class="header-title">Import</h1>
    </div>
    <div class="spacer"></div>
    <div>
      <button mat-icon-button>
        <mat-icon class="close-icon" (click)="closeDialog()" color="warn">close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-dialog-content style="display: block;  min-height: 60% !important;">
    <p class="mt-3">Select the data file to import data </p>
    <mat-form-field appearance="outline">
      <mat-label>Select Data File</mat-label>
      <ngx-mat-file-input #importFile
        [accept]="['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']"
        (change)="onSelectFile($event)" placeholder=" Select Data File">
      </ngx-mat-file-input>
      <mat-icon matSuffix>attachment</mat-icon>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="row">
      <div class="col-12 col-md-4">
        <button mat-flat-button class="w-100" [disabled]="!importFile.value" color="primary"
          (click)="fileImportBtnClick()">
          Import
        </button>
      </div>
      <div class="col-md-4">
        <div class="spacer"></div>
      </div>
      <div class="col-12 col-md-4">
        <button mat-flat-button class="w-100" color="warn" (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>

<ng-template #addNew>
  <div class="position-relative">
    <h1 mat-dialog-title class="header-title">Add New {{ config.title }}</h1>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="divider my-4"></div>
  <div-dialog-content>
    <ng-container *ngFor="let column of newField; let i = index">
      <mat-form-field appearance="outline" class="col-width" *ngIf="!config[column]?.type">
        <mat-label>{{ colun | titlecase }}</mat-label>
        <input name="field_{{ i }}" matInput required [(ngModel)]="obj[column]" />
      </mat-form-field>
    </ng-container>
  </div-dialog-content>
  <div class="divider my-4"></div>

  <div class="action-buttons pb-4" mat-dialog-actions>
    <button mat-button class="btn mx-3" [disabled]="canDisabled()" (click)="importFile()">
      Save
    </button>
    <button mat-button class="btn mx-3" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>


<ng-template #edit>
  <button mat-icon-button class="close-button">
    <mat-icon class="close-icon" (click)="closeDialog()" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{dialogData?.title}}</h1>
  <form #editInfoForm="ngForm">
    <div mat-dialog-content>
      <div class="row">
        <ng-container *ngFor="let field of getObjectKeys(editModel,'edit'); let i=index">
          <ng-container *ngIf="displayField(field)">

            <div class="col-12" *ngIf="config?.elementType[field]?.type === 'dropdown'">
              <mat-form-field appearance="outline">
                <mat-label>{{field | uppercase}}</mat-label>
                <mat-select [disabled]="disableField(field)" [required]="isRequired(field)" name=editField{{i}}
                  [(ngModel)]="editModel[field]">
                  <mat-option *ngFor="let dvalue of config.elementType[field]?.allowedValues" [value]="dvalue">
                    {{dvalue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12" *ngIf="config?.elementType[field]?.type === 'textarea'">
              <mat-form-field appearance="outline">
                <mat-label>{{field | uppercase}}</mat-label>
                <textarea rows=10 [disabled]="disableField(field)" matInput [required]="isRequired(field)"
                  name=editField{{i}} [(ngModel)]="editModel[field]"></textarea>
              </mat-form-field>
            </div>

            <div class="col-12" *ngIf="!config?.elementType[field]?.type">
              <mat-form-field appearance="outline">
                <mat-label>{{field | uppercase}}</mat-label>
                <input [disabled]="disableField(field)" matInput [required]="isRequired(field)" name=editField{{i}}
                  [(ngModel)]="editModel[field]" />
              </mat-form-field>
            </div>

          </ng-container>

        </ng-container>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center mb-1">
            <button class="w-75" mat-flat-button color="primary" (click)="editInfoAction()">Save</button>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center mb-1">
            <button class="w-75" mat-flat-button color="warn" (click)="closeDialog()">Cancel</button>
          </div>
        </div>

      </div>

    </div>
  </form>
</ng-template>

<ng-template #delete>
  <button mat-icon-button class="close-button">
    <mat-icon class="close-icon" (click)="closeDialog()" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{dialogData.title}}</h1>
  <form #editInfoForm="ngForm">
    <div mat-dialog-content>

      <div class="row">
        <ng-container *ngFor="let field of getObjectKeys(deleteModel,'delete'); let i=index">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{field | uppercase}}</mat-label>
              <input readonly=true matInput name=deleteField{{i}} [(ngModel)]="deleteModel[field]" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div class="primary-text">
        <h1>Are you sure want to delete this item?</h1>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center mb-1">
            <button class="w-75 text-center" mat-flat-button color="primary"
              (click)="deleteInfoAction(deleteModel)">Delete</button>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-center mb-1">
            <button class="w-75 text-center" mat-flat-button color="warn" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #view>
  <button mat-icon-button class="close-button">
    <mat-icon class="close-icon" (click)="closeDialog()" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{dialogData.title}}</h1>
  <form #editInfoForm="ngForm">
    <div mat-dialog-content>
      <div class="row">
        <ng-container *ngFor="let field of getObjectKeys(viewModel,'view'); let i=index">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{field | uppercase}}</mat-label>
              <input readonly=true matInput name=viewField{{i}} [(ngModel)]="viewModel[field]" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center mb-1">
            <button class="w-75 text-center" mat-flat-button color="warn" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </div>

    </div>
  </form>
</ng-template>