<div>
  <div class="my-1 text-center">
    <a mat-button href="/store/terms" target="_blank">Terms and conditions</a>
    <a mat-button href="/store/privacy" target="_blank">Privacy Policy</a>
    <a mat-button href="/store/refund" target="_blank"
      >Cancellation and Refund Policy</a
    >
    <a mat-button routerLink="support">Contact Us</a>
    <a mat-button routerLink="about">About Us</a>
  </div>
  <div class="text-center my-1">
    Copyright &copy; {{ year }} {{ copyright }}
  </div>
</div>
