import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/models/order';
import { CommonUtil } from 'src/app/shared/common.util';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent {
  @Output()
  closeAction = new EventEmitter<any>();
  @Output()
  payAction = new EventEmitter<any>();

  @Input()
  mode: string;

  @Input()
  order: Order;

  invoiceUrl: string = '';
  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private commonUtil: CommonUtil,
    private invoiceService: InvoiceService,
    private preLoader: PreLoaderService
  ) {}
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  isEligibleForPayment() {
    return this.commonUtil.isEligibleForPayment(this.order);
  }

  async printInvoice() {
    this.preLoader.show();
    await this.invoiceService
      .createInvoice(this.order.id)
      .then((response: any) => {
        window.open(response?.url, '_blank');
        this.preLoader.hide();
      });
  }

  redirectPaymentLink() {
    this.router.navigateByUrl('/order/payment/' + this.order.id);
  }
  onCloseBtnClick() {
    this.closeAction.emit();
  }

  payBtnClicked() {
    this.payAction.emit();
  }
}
