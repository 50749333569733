import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-kyc',
  templateUrl: './upload-kyc.component.html',
  styleUrls: ['./upload-kyc.component.scss']
})
export class UploadKycComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  panFileChangeEvent(event: any): void {

  }

  GSTFileChangeEvent(event: any): void {

  }

}
