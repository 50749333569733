import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AppMaterialModule } from './material-module';
import { PreLoaderService } from './shared/services/pre-loader.service';
import { CommonUtil } from './shared/common.util';
import { ConfigService } from './shared/services/config.service';
import { PreloaderComponent } from './shared/components/preloader/preloader.component';
import { MenusComponent } from './shared/components/menus/menus.component';
import { HomeComponent } from './pages/components/home/home.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { ProfileComponent } from './pages/components/profile/profile.component';
import { LoginComponent } from './pages/components/login/login.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ViewOrderComponent } from './pages/components/view-order/view-order.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ThemingService } from './theme/services/theming.service';
import { UnregisteredComponent } from './pages/components/unregistered/unregistered.component';
import { MyOrderComponent } from './pages/components/my-order/my-order.component';
import { OrderBookComponent } from './pages/components/order-book/order-book.component';
import { BrowseBooksComponent } from './pages/components/browse-books/browse-books.component';
import { PaymentComponent } from './pages/components/payment/payment.component';
import { UserViewOrderComponent } from './pages/components/user-view-order/user-view-order.component';
import { FilterBookComponent } from './shared/components/filter-book/filter-book.component';
import { PaymentConfirmComponent } from './pages/components/payment-confirm/payment-confirm.component';
import { UploadKycComponent } from './pages/components/upload-kyc/upload-kyc.component';
import { DefaultPaymentComponent } from './shared/components/default-payment/default-payment.component';
import { BrandingService, TtBrandingLibModule } from 'tt-branding-lib';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { TtCommonLibModule } from 'tt-common-lib';
import { TtPageComponentsLibModule } from 'tt-page-components-lib';
import { TtWebStoreModule } from 'tt-web-store';
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    },
    {
      provider: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
    },
  ],
  tosUrl: '/store/terms',
  privacyPolicyUrl: '/store/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
};

export function EmailValidator(control: FormControl): ValidationErrors {
  return !control.value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      control.value
    )
    ? null
    : { email: true };
}

export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
  return `This is not a valid email address`;
}
export function minlengthValidationMessage(err, field) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err, field) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions.max}`;
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PreloaderComponent,
    MenusComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    UnauthorizedComponent,
    DataTableComponent,
    PaymentConfirmComponent,
    ViewOrderComponent,
    UnregisteredComponent,
    MyOrderComponent,
    OrderBookComponent,
    BrowseBooksComponent,
    PaymentComponent,
    UserViewOrderComponent,
    FilterBookComponent,
    UploadKycComponent,
    DefaultPaymentComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    ReactiveFormsModule,
    TtCommonLibModule,
    TtPageComponentsLibModule,
    TtWebStoreModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      validators: [{ name: 'email', validation: EmailValidator }],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'email', message: EmailValidatorMessage },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
    }),
    FormlyMaterialModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    TtBrandingLibModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
  ],
  providers: [
    DatePipe,
    PreLoaderService,
    CommonUtil,
    Title,
    ConfigService,
    AuthGuard,
    ThemingService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: BrandingService) => () => config.load(),
      deps: [BrandingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
