import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtil } from '../../common.util';
import { User } from '../../models/user';
import { BrandingService } from 'tt-branding-lib';
@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit {
  @Input() user: any;
  @Output() logout = new EventEmitter<User>();
  @Output() closeSideNav = new EventEmitter();

  logo: any;
  isHandset$: Observable<boolean>;
  constructor(private branding: BrandingService, commonUtil: CommonUtil) {
    this.isHandset$ = commonUtil.isHandset$;
  }

  ngOnInit(): void {
    this.logo = this.branding.config.app.logo;
  }

  isEligible(name: string) {
    switch (name) {
      case 'library':
        return (
          this.user?.role?.indexOf('admin') > -1 ||
          this.user?.role?.indexOf('manager') > -1
        );
        break;
      case 'feedback':
        return (
          this.user?.role?.indexOf('admin') > -1 ||
          this.user?.role?.indexOf('owner') > -1
        );
        break;
      case 'coupon':
        return (
          this.user?.role?.indexOf('admin') > -1 ||
          this.user?.role?.indexOf('manager') > -1
        );
        break;
      case 'manage-user':
        return (
          this.user?.role?.indexOf('admin') > -1 ||
          this.user?.role?.indexOf('manager') > -1
        );
        break;
      case 'review-order':
        return (
          this.user?.role?.indexOf('admin') > -1 ||
          this.user?.role?.indexOf('manager') > -1 ||
          this.user?.role?.indexOf('dispatcher') > -1
        );
        break;
      default:
        return false;
    }
  }

  logoutBtnClicked() {
    this.logout.emit();
  }

  closeSidebar() {
    this.closeSideNav.emit();
  }
}
