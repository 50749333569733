<div class="container">
  <div *ngIf="user.usertype === 'school'">
    <div>
      <mat-toolbar color="accent">
        <div>School Information</div>
      </mat-toolbar>
    </div>
    <mat-card class="ml-2 mr-2">
      <div class="row">
        <div
          class="col-12"
          [ngClass]="{
            'col-md-12': mode != 'mini',
            'col-md-6': mode == 'mini'
          }"
        >
          <div>
            <h2>School Name</h2>
          </div>
          <table class="table table-borderless rounded-lg w-100">
            <tr>
              <td>{{ user.name }}</td>
            </tr>
          </table>

          <div>
            <h2>Contact Details</h2>
          </div>

          <table class="table table-borderless rounded-lg w-100">
            <tr *ngIf="user.email">
              <td>Email</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr *ngIf="user.mobile">
              <td>Mobile</td>
              <td>{{ user.mobile }}</td>
            </tr>
            <ng-container *ngIf="mode != 'mini'">
              <tr *ngIf="user.correspondent">
                <td>Correspondent</td>
                <td>
                  <span>
                    {{ user.correspondent }}
                  </span>
                  <span *ngIf="user.correspondentMobile">
                    / {{ user.correspondentMobile }}
                  </span>
                </td>
              </tr>
              <tr *ngIf="user.principal">
                <td>Principal</td>
                <td>
                  {{ user.principal }}
                  <span *ngIf="user.principalMobile">
                    / {{ user.principalMobile }}
                  </span>
                </td>
              </tr>
              <tr *ngIf="user.admin">
                <td>Admin</td>
                <td>
                  <span>{{ user.admin }}</span>
                  <span *ngIf="user.adminMobile">
                    / {{ user.adminMobile }}
                  </span>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
        <div
          class="col-6"
          [ngClass]="{
            'col-md-12': mode != 'mini',
            'col-md-6': mode == 'mini'
          }"
        >
          <div>
            <h2>School Address</h2>
          </div>
          <table class="table table-borderless rounded-lg w-100">
            <tr>
              <td>
                <div>{{ user.address1 }}</div>
                <div>{{ user.address2 }}</div>
                <div>{{ user.city }}</div>
                <div>{{ user.state }}</div>
                <div>{{ user.pincode }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>

  <div *ngIf="user.usertype != 'school'">
    <div>
      <mat-toolbar color="accent">
        <div>Business Information</div>
      </mat-toolbar>
    </div>
    <mat-card class="ml-2 mr-2">
      <div class="row">
        <div
          class="col-12"
          [ngClass]="{
            'col-md-12': mode != 'mini',
            'col-md-6': mode == 'mini'
          }"
        >
          <div>
            <h2>Business Name</h2>
          </div>
          <table class="table table-borderless rounded-lg w-100">
            <tr>
              <td>{{ user.name }}</td>
            </tr>
          </table>

          <div>
            <h2>Contact Details</h2>
          </div>

          <table class="table table-borderless rounded-lg w-100">
            <tr *ngIf="user.email">
              <td>Email</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr *ngIf="user.mobile">
              <td>Mobile</td>
              <td>{{ user.mobile }}</td>
            </tr>
          </table>
        </div>
        <div
          class="col-12"
          [ngClass]="{
            'col-md-12': mode != 'mini',
            'col-md-6': mode == 'mini'
          }"
        >
          <div>
            <h2>Business Address</h2>
          </div>
          <table class="table table-borderless rounded-lg w-100">
            <tr>
              <td>
                <div>{{ user.address1 }}</div>
                <div>{{ user.address2 }}</div>
                <div>{{ user.city }}</div>
                <div>{{ user.state }}</div>
                <div>{{ user.pincode }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="mt-5 mb-5" *ngIf="mode != 'mini'">
    <div>
      <mat-toolbar color="accent">
        <div>Tax Information</div>
      </mat-toolbar>
    </div>
    <mat-card class="ml-2 mr-2">
      <div class="row">
        <div class="col-12">
          <table class="table table-borderless rounded-lg w-100">
            <tr>
              <td>Business PAN</td>
              <td>{{ user.pan }}</td>
            </tr>
            <tr *ngIf="user.gstin">
              <td>GST Number</td>
              <td>{{ user.gstin }}</td>
            </tr>

            <tr>
              <td>{{ merchantTax.name }}</td>
              <td>{{ merchantTax.gst }}</td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>
</div>
