<div class="container" *ngIf="reviewPage">
  <div class="row">
    <div class="col-12">
      <div class="mt-2 mb-2">
        <mat-toolbar color="accent">
          <div>Review Order and Checkout</div>
        </mat-toolbar>
        <div>
          <mat-card>
            <section class="mt-1">
              <h2>Billing Address</h2>
              <div class="d-flex flex-column m-2">
                <div>
                  <strong>{{ order.name }}</strong>
                </div>
                <div>{{ order.billing.address1 }}</div>
                <span>{{ order.billing.address2 }}</span>
                <span>{{ order.billing.city }}</span>
                <div>{{ order.billing.district }}</div>
                <div>{{ order.billing.state }}</div>
                <div>{{ order.billing.pincode }}</div>
                <div *ngIf="order.billing.landmark">
                  Landmark :{{ order.billing.landmark }}
                </div>
                <div class="mt-1">
                  Mobile: {{ order.billing.mobile }} <small></small>
                </div>
                <div class="d-flex flex-column mt-1">
                  <div>
                    {{ order.email }}
                    <small>*For communication purposes only</small>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <mat-checkbox (change)="onAddressChange($event)">
                <strong>
                  Is your delivery address different from billing
                  address?</strong
                >
              </mat-checkbox>
            </section>
          </mat-card>
        </div>

        <div class="mt-2">
          <mat-accordion>
            <mat-expansion-panel [expanded]="panelOpenState">
              <mat-expansion-panel-header style="color: white !important">
                <mat-panel-title> Delivery Address </mat-panel-title>
              </mat-expansion-panel-header>
              <section class="mt-3">
                <form #deliveryForm="ngForm">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <input
                          [(ngModel)]="order.delivery.name"
                          matInput
                          name="name"
                          placeholder="Subashree"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Contact Number</mat-label>
                        <input
                          [(ngModel)]="order.delivery.mobile"
                          matInput
                          name="mobile"
                          required
                          minlength="10"
                          maxlength="12"
                          placeholder="97900 29577"
                        />
                        <mat-hint align="end"
                          >{{ order.delivery.mobile.length }} / 12</mat-hint
                        >
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Address</mat-label>
                        <textarea
                          name="address1"
                          required
                          matInput
                          [(ngModel)]="order.delivery.address1"
                          placeholder="Ex. 25/67, New Street"
                        ></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label>Address 2</mat-label>
                        <textarea
                          name="address2"
                          matInput
                          [(ngModel)]="order.delivery.address2"
                        ></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input
                          matInput
                          required
                          name="city"
                          placeholder="Ex. Vandavasi"
                          [(ngModel)]="order.delivery.city"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>District</mat-label>
                        <input
                          matInput
                          required
                          name="district"
                          placeholder="Ex. Tiruvannamalai "
                          [(ngModel)]="order.delivery.district"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>State</mat-label>
                        <input
                          matInput
                          required
                          name="state"
                          placeholder="Ex. Tamil Nadu"
                          [(ngModel)]="order.delivery.state"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Postal Code</mat-label>
                        <input
                          matInput
                          name="pincode"
                          #postalCode
                          minlength="6"
                          required
                          maxlength="6"
                          [(ngModel)]="order.delivery.pincode"
                          placeholder="Ex. 604408"
                        />
                        <mat-hint align="end"
                          >{{ order.delivery.pincode.length }} / 6</mat-hint
                        >
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </section>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="mt-2">
          <mat-card *ngIf="booksInCart && booksInCart.length > 0">
            <table class="table table-borderless mt-1">
              <thead>
                <tr>
                  <th scope="col">SI.No</th>
                  <th scope="col">Book</th>
                  <th scope="col">QTY</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tr *ngFor="let book of booksInCart; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ book.id }}-{{ book.grade }} - {{ book.name }} -
                  {{ book.language }}
                </td>
                <td>{{ book.orderQty }}</td>
                <td>{{ book.amount }}</td>
                <td>{{ book.orderQty * book.amount | currency: "INR" }}</td>
              </tr>

              <tr></tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Total:</td>
                <td>{{ order.summary.total | currency: "INR" }}</td>
              </tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Discount:</td>
                <td>{{ order.summary.discount | currency: "INR" }}</td>
              </tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Net Total:</td>
                <td>{{ order.summary.netamount | currency: "INR" }}</td>
              </tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Total QTY:</td>
                <td>{{ order.summary.totalqty }}</td>
              </tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Delivery Chargers:</td>
                <td>{{ 0 | currency: "INR" }}</td>
              </tr>
              <tr style="font-weight: bolder">
                <td colspan="4" class="text-right mr-3">Total Payable:</td>
                <td>{{ order.summary.netamount | currency: "INR" }}</td>
              </tr>
            </table>
            <div class="mt-3 text-center">
              <div
                *ngIf="deliveryForm.invalid"
                class="alert alert-danger"
                role="alert"
              >
                <div>
                  <strong>Delivery Address required </strong>
                </div>
                <div>
                  Please enter a valid delivery address or Unselect "Is your
                  delivery address different from the billing address?"
                </div>
              </div>
            </div>
            <div class="mt-3 text-center">
              <button
                (click)="updateOrderBtnClicked()"
                color="warn"
                mat-flat-button
              >
                Back
              </button>
              <button
                [disabled]="deliveryForm.invalid"
                class="ml-4"
                (click)="payBtnClicked()"
                color="primary"
                mat-flat-button
              >
                Pay Order
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="!reviewPage">
  <app-filter-book
    [books]="rawbooks"
    [earlyloading]="false"
    (filterEvent)="updateDisplay($event)"
  ></app-filter-book>
  <div class="col-12">
    <div class="row justify-content-md-center">
      <div class="col-12 col-lg-6" *ngIf="books.length > 0">
        <div class="mt-2">
          <mat-toolbar color="accent">
            <div>Search Result</div>
            <div class="spacer"></div>
          </mat-toolbar>
          <mat-card class="p-1">
            <div>
              <cdk-virtual-scroll-viewport
                [itemSize]="books.length"
                style="height: 35vh"
              >
                <table class="table table-borderless mt-3">
                  <thead>
                    <tr>
                      <th scope="col">SI.No</th>
                      <th scope="col">Book</th>
                      <th scope="col">QTY</th>
                    </tr>
                  </thead>
                  <tr *cdkVirtualFor="let book of books; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ book.grade }} - {{ book.name }} - {{ book.language }}
                    </td>
                    <td>
                      <input
                        style="width: 40px !important"
                        [disabled]="!book.stock"
                        (input)="onQtyChange($event.target.value, book.id)"
                        type="text"
                      />
                    </td>
                  </tr>
                </table>
              </cdk-virtual-scroll-viewport>
            </div>
          </mat-card>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="mt-2">
          <mat-toolbar color="accent">
            <div>Shopping Cart</div>
          </mat-toolbar>
          <mat-card
            class="ml-2 mr-2"
            *ngIf="!(booksInCart && booksInCart.length > 0)"
          >
            <div class="mt-3 mb-3">
              Your order cart is empty. Please select books on left side and
              enter the QTY in textbox
            </div>
          </mat-card>

          <mat-card *ngIf="booksInCart && booksInCart.length > 0">
            <cdk-virtual-scroll-viewport
              [itemSize]="booksInCart.length"
              style="height: 25vh"
            >
              <table class="table table-borderless mt-1">
                <thead>
                  <tr>
                    <th scope="col">SI.No</th>
                    <th scope="col">Book</th>
                    <th scope="col">QTY</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tr *cdkVirtualFor="let book of booksInCart; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{ book.id }}-{{ book.grade }} - {{ book.name }} -
                    {{ book.language }}
                  </td>
                  <td>
                    <input
                      style="width: 40px !important"
                      [value]="book.orderQty"
                      [disabled]="!book.stock"
                      (input)="onQtyChange($event.target.value, book.id)"
                      type="text"
                    />
                  </td>
                  <td>{{ book.amount }}</td>
                  <td class="text-right">
                    {{ book.orderQty * book.amount | currency: "INR" }}
                  </td>
                  <td class="text-danger">
                    <button mat-icon-button (click)="onDeleteBook(book.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </cdk-virtual-scroll-viewport>
            <table class="table table-borderless" style="font-weight: bolder">
              <tr>
                <td colspan="4" class="text-right">Total:</td>
                <td class="text-right">
                  {{ summary.total | currency: "INR" }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="4" class="text-right">Discount:</td>
                <td class="text-right">
                  {{ summary.discount || 0 | currency: "INR" }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="4" class="text-right">Net Total:</td>
                <td class="text-right">
                  {{ summary.netamount | currency: "INR" }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td colspan="4" class="text-right">Total QTY:</td>
                <td class="text-right">{{ summary.totalqty }}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="4" class="text-right">Delivery Chargers:</td>
                <td class="text-right">{{ 0 | currency: "INR" }}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="4" class="text-right">Total Payable:</td>
                <td class="text-right">
                  {{ summary.netamount | currency: "INR" }}
                </td>
                <td></td>
              </tr>
            </table>
            <div class="mt-3 text-right">
              <button
                (click)="reviewBtnClicked()"
                color="primary"
                mat-flat-button
              >
                Review and Checkout
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
