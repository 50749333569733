import { Component, OnInit } from '@angular/core';
import { PreLoaderService } from '../../services/pre-loader.service';


@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent {
  active: boolean;
  constructor(public preloader: PreLoaderService) {
    preloader.loading$.subscribe(status => this.active = status);
  }
}
