<div class="container-fuild m-0 p-0">
  <div class="mt-3">
    <div class="text-center">
      <h1>Welcome {{ user.name }}</h1>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-md-center">
      <div class="mt-3 mb-3 col-12 col-lg-6" style="min-height: 300px">
        <app-profile mode="mini"></app-profile>
      </div>

      <div
        *ngIf="user?.kyc !== 'verified'"
        class="mt-3 mb-3 col-12 col-lg-6"
        style="min-height: 300px"
      >
        <mat-toolbar color="accent">
          <span>Partners Discount Program</span>
        </mat-toolbar>
        <div>
          <mat-card class="ml-2 mr-2" style="min-height: 300px">
            <table class="table table-borderless">
              <thead>
                <th>Purchase Amount</th>
                <th>Discount(%)</th>
              </thead>
              <tbody>
                <tr *ngFor="let discount of discounts">
                  <td>>={{ discount.amount | currency: "INR" }}</td>
                  <td>{{ discount.rate | percent }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="text-center">
                Upload KYC document to get discount upto
                {{ maxDiscount | percent }}
                <div>
                  <button mat-flat-button color="primary">Upload KYC</button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
      <div
        *ngIf="user?.kyc === 'verified'"
        class="mt-3 mb-3 col-12 col-lg-6"
        style="min-height: 300px"
      >
        <mat-toolbar color="accent">
          <span>Partners Discount Program</span>
        </mat-toolbar>
        <div>
          <mat-card class="ml-2 mr-2" style="min-height: 300px">
            <div class="text-center">
              <mat-icon
                class="text-center primary-text"
                style="
                  height: 100px !important;
                  width: 150px !important;
                  font-size: 145px;
                "
              >
                account_balance_wallet
              </mat-icon>
              <div>
                All your order eligible for flat {{ user.discount }} % discount.
              </div>
              <button
                class="mt-2"
                mat-flat-button
                [routerLink]="['/order/books']"
                color="warn"
              >
                Order Now
              </button>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="mt-3 mb-3 col-12">
        <mat-toolbar color="accent">
          <span>Latest 3 transactions Status</span>
        </mat-toolbar>
        <div>
          <mat-card class="ml-2 mr-2">
            <app-my-order mode="mini"></app-my-order>
            <div class="text-center">
              <button
                class="mt-2"
                mat-flat-button
                [routerLink]="['/my-orders']"
                color="warn"
              >
                Order History
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
