import { Component, OnInit } from '@angular/core';
import { BrandingService } from 'tt-branding-lib';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyright: any;
  year: number;
  constructor(private branding: BrandingService) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.copyright = this.branding.config?.legal?.copyright;
  }
}
