import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FirebaseUISignInFailure,
  FirebaseUISignInSuccessWithAuthResult,
} from 'firebaseui-angular';
import { CommonUtil } from 'src/app/shared/common.util';

import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';
import { UserService } from 'src/app/shared/services/user.service';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  logo;
  retailLoginUrl;
  returnUrl!: string;
  user: any;
  config: any;

  constructor(
    private preloader: PreLoaderService,
    private userService: UserService,
    private authUserService: FirebaseAuthService,
    private commonutil: CommonUtil,
    private branding: BrandingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.preloader.hide();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.logo = this.branding.config.app.logo;
    this.retailLoginUrl = this.branding.config.app.retailLoginUrl;
    this.authUserService.currentuser$.subscribe((data) => {
      this.user = data;
      if (this.user?.uid) {
        if (Boolean(this.user.unregistered)) {
          this.router.navigateByUrl('/unregistered');
        } else if (!Boolean(this.user.authorized)) {
          this.router.navigateByUrl('/unauthorized');
        } else {
          this.router.navigateByUrl(this.returnUrl);
        }
      }
    });
  }
  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    let userInfo = signInSuccessData.authResult.user;
    if (userInfo) {
      let user = this.commonutil.getUserInformation(userInfo);
      this.userService.logUsers(user);
    }
  }
  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log(errorData);
  }

  uiShownCallback() {
    console.log('uiShownCallback');
  }
}
