<div class="container" *ngIf="showErrorMessage">
    <mat-card>
        <mat-card-content>
            <div class="alert alert-danger" role="alert">
                <div class="text-center">
                    <span style="font-size: 10em;">
                        <fa-icon [icon]="fasUserFailed"></fa-icon>
                    </span>
                    <h1> Registration Failed</h1>
                    <div>
                        Account already exists!. If you forget your username or password, use the below link to reset
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="container" *ngIf="confirmationMessage">
    <mat-card>
        <mat-card-content>
            <div class="alert alert-info" role="alert">
                <div class="text-center">
                    <span style="font-size: 10em;">
                        <fa-icon [icon]="fasRegistrationConfirm"></fa-icon>
                    </span>
                    <h1> Thanks for your Registration</h1>
                    <div>
                        <div>We have send you an confirmation email to <b>{{model.email}}</b> </div>
                        <div>Please confirm your email address to activate your account</div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="container" *ngIf="!(confirmationMessage || showErrorMessage)">
    <mat-card class="m-2">
        <mat-toolbar class="m-0" color="primary">
            <div>Complete Schools and Partners Registration</div>
        </mat-toolbar>
        <div class="mt-2 mb-2">
            <span>{{ description }}</span>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()">
            <mat-vertical-stepper linear>
                <mat-step *ngFor="let step of steps; let index = index; let last = last;"
                    [stepControl]="form.at(index)">
                    <ng-template matStepLabel>{{ step.label }}</ng-template>
                    <formly-form [form]="form.at(index)" [model]="model" [fields]="step.fields"
                        [options]="options[index]">
                    </formly-form>

                    <div class="row">
                        <div class="col-12 col-lg-4" *ngIf="index !== 0">
                            <button matStepperPrevious mat-flat-button color="warn" class="w-50" type="button"
                                (click)="prevStep(index)">Back</button>
                        </div>

                        <div class="col-12 col-lg-4" *ngIf="!last">
                            <button matStepperNext mat-flat-button color="primary" class="w-50" type="button"
                                [disabled]="!form.at(index).valid" (click)="nextStep(index)">Next</button>
                        </div>

                        <div class="col-12 col-lg-4" *ngIf="last">
                            <button mat-flat-button color="primary" [disabled]="!form.valid" class="w-50"
                                type="submit">Submit</button>
                        </div>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </form>
    </mat-card>
</div>