<div class="container">
    <mat-toolbar class="mt-5" color="primary">
        <div>Transaction Status</div>
    </mat-toolbar>
    <div class="mb-1">
        <mat-card *ngIf="status==='success'">
            <div class="text-center">
                <span style="color: green; font-size: 10em;">
                    <fa-icon [size]="lg" [icon]="faSuccess"></fa-icon>
                </span>
                <h2>Transaction Successful</h2>
                <p>Your payment of <b>{{order?.orderamount |currency: "INR" }}</b> was successful and
                    the details have been sent to you on registered email.</p>
                <strong><b>{{order?.email}}</b></strong>
                <div class="mt-2">
                    <button mat-flat-button routerLink="/my-orders" color="primary">My Orders</button>
                </div>
            </div>
        </mat-card>
        <mat-card *ngIf="status!=='success'">
            <div class="text-center">
                <span style="color:red; font-size: 10em;">
                    <fa-icon [size]="lg" [icon]="faFailed"></fa-icon>
                </span>
                <h2>Transaction Failed</h2>
                <P>Your transaction has failed due to some technical error. Please try again.</P>
                <div class="mt-2">
                    <button mat-flat-button (click)="redirectPaymentLink()" color="primary">Make
                        Payment</button>
                </div>
            </div>
        </mat-card>
    </div>
</div>