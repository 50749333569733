import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandingService } from 'tt-branding-lib';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private httpClient: HttpClient,
    private branding: BrandingService
  ) {}

  createInvoice(oid: string) {
    let apiUrl = this.branding.config.api.invoice;
    let url = this.branding.config.api.baseUrl + apiUrl.create + 'oid=' + oid;
    return this.httpClient.get(url).toPromise();
  }
}
