<div class="container-fluid">
  <div class="mt-1 mb-2">
    <mat-card>
      <span class="primary-text">Browse Catalogue by</span>
      <div class="row justify-content-center">
        <div class="col-12 col-md-3 d-flex justify-content-center">
          <mat-form-field color="primary" appearance="legacy">
            <mat-label>Category</mat-label>
            <mat-select
              [(ngModel)]="categorySelection"
              (selectionChange)="onCategorySelection($event.value)"
              [multiple]="true"
            >
              <mat-option
                *ngFor="let category of categories"
                [value]="category"
              >
                {{ category }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-3 d-flex justify-content-center">
          <mat-form-field color="primary" appearance="legacy">
            <mat-label>SubCategory</mat-label>
            <mat-select
              [(ngModel)]="subCategorySelection"
              (selectionChange)="onSubCategorySelection($event.value)"
              [multiple]="true"
            >
              <mat-option
                *ngFor="let subcategory of subcategories"
                [value]="subcategory"
              >
                {{ subcategory }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            (click)="clearFilter()"
            *ngIf="isEligibleToDisplay()"
            class="ml-2"
            mat-mini-fab
            color="accent"
          >
            <mat-icon>filter_alt_off</mat-icon>
          </button>
        </div>
        <div class="col-12 col-md-3 d-flex justify-content-center">
          <div *ngIf="!isEligibleToDisplay()">Showing {{ resultCount }}</div>
          <div *ngIf="isEligibleToDisplay()">
            Showing {{ resultCount }} of {{ books?.length }}
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
