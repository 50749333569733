import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Book } from 'src/app/models/book';
import { BookService } from 'src/app/shared/services/book.service';
import { PreLoaderService } from 'src/app/shared/services/pre-loader.service';

@Component({
  selector: 'app-browse-books',
  templateUrl: './browse-books.component.html',
  styleUrls: ['./browse-books.component.scss'],
})
export class BrowseBooksComponent implements AfterViewInit {
  constructor(
    private bookService: BookService,
    private preLoaderService: PreLoaderService,
    private cdRef: ChangeDetectorRef
  ) {}

  books: any[] = [];
  rawbooks: any[];

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  updateDisplay(books: Book[]) {
    this.books = books;
  }

  ngAfterViewInit(): void {
    this.preLoaderService.show();
    this.bookService.getBooks().subscribe((booksData: any[]) => {
      this.rawbooks = booksData;
      this.preLoaderService.hide();
    });
  }
}
