import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from '../models/order';
import { User } from './models/user';

@Injectable()
export class CommonUtil {
  constructor(private breakpointObserver: BreakpointObserver) {}

  getUserInformation(userInfo: any): User {
    return (
      (userInfo && {
        id: userInfo.uid,
        uid: userInfo.uid,
        displayName: userInfo.displayName || '',
        email: userInfo.email || '',
        emailVerified: userInfo.emailVerified,
        photoURL: userInfo.photoURL || '',
        phoneNumber: userInfo.phoneNumber || '',
        creationTime: userInfo.metadata.creationTime || '',
        lastSignInTime: userInfo.metadata.lastSignInTime || '',
      }) ||
      {}
    );
  }

  public getOrderDate(dateStr: string) {
    return Date.parse(dateStr);
  }

  sortUserInfo(data) {
    return data.sort(
      (a: User, b: User) =>
        Date.parse(b.lastSignInTime) - Date.parse(a.lastSignInTime)
    );
  }

  sortOrders(data) {
    return data.sort(
      (a: Order, b: Order) =>
        this.getOrderDate(b.createdDate) - this.getOrderDate(a.createdDate)
    );
  }

  public isEligibleForPayment(order: Order) {
    return (
      order?.status === 'Payment Pending' ||
      order?.status === 'Payment Not Captured' ||
      order?.status === 'Payment Failed'
    );
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
}
