<div class="container">
  <div class="m-3 mt-10">
    <mat-toolbar class="m-0 p-0" color="accent">
      <div class="title-center">Access Forbidden</div>
    </mat-toolbar>
    <mat-card class="m-2 p-2 mat-elevation-z10">
      <div class="row">
        <div class="col-md-2 text-center">
          <mat-icon class="big-mat-icon">warning</mat-icon>
          <br />
          <span>Code: 403</span>
        </div>
        <div class="col-md-10">
          <h3>Unauthorized</h3>
          <p>
            Sorry, your access is refused due to security reasons of our
            application.<br />Please contact administrator to obtain permission
          </p>
        </div>
      </div>
    </mat-card>
  </div>
</div>
