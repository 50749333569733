import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements AfterViewInit, OnChanges {
  @Input()
  data: any[];

  @Input()
  config: any;

  @Output()
  editAction = new EventEmitter();

  @Output()
  viewAction = new EventEmitter();

  @Output()
  deleteAction = new EventEmitter();

  @Output()
  importAction = new EventEmitter<any>();

  @Output()
  refreshAction = new EventEmitter<any>();

  @Output()
  newAction = new EventEmitter<any>();

  @Output()
  editSaveAction = new EventEmitter<any>();

  initialData: any[] = [];
  galleryData: any[] = [];

  checkDisplay(action: any) {
    if (this.config.mode === 'mini') {
      return false;
    }
    switch (action) {
      case 'refresh':
        return this.refreshAction.observers.length > 0;
      case 'import':
        return this.importAction.observers.length > 0;
      case 'export':
        return this.config.export;
      case 'new':
        return this.newAction.observers.length > 0;
      case 'edit':
        return this.editAction.observers.length > 0;
      case 'delete':
        return this.deleteAction.observers.length > 0;
      case 'view':
        return (
          this.config.view?.display || this.viewAction.observers.length > 0
        );
    }
  }

  addNewAction() {
    this.newAction.emit();
  }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('edit')
  editContainer: TemplateRef<any>;

  @ViewChild('import')
  importContainer: TemplateRef<any>;

  @ViewChild('view')
  viewContainer: TemplateRef<any>;

  @ViewChild('delete')
  deleteContainer: TemplateRef<any>;

  @ViewChild('editInfoForm')
  editInfoForm: NgForm;

  editModel: any;
  viewModel: any;
  deleteModel: any;

  displayedColumns = [];
  headers: any[];
  dataSource: MatTableDataSource<any>;
  importFile: File;
  dialogData: any;

  constructor(
    private store: AngularFirestore,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data.currentValue) {
      this.initialData = JSON.parse(JSON.stringify(changes.data.currentValue));
      this.galleryData = this.initialData;
      this.dataSource = new MatTableDataSource();

      if (this.config.elementType) {
        let customElements = Object.keys(this.config.elementType);
        customElements.forEach(async (x) => {
          let element = this.config.elementType[x];
          if (element.type === 'dropdown' && element.documentId) {
            (await this.getDropdownValueAsArray(element.documentId)).subscribe(
              (data) => {
                this.config.elementType[x].allowedValues = data;
              }
            );
          }
        });
      }

      this.dataSource.data = this.getTableData(this.initialData);
      if (this.dataSource.data.length > 0) {
        this.displayedColumns = Object.keys(this.dataSource.data[0]);
        this.headers = [...this.displayedColumns];
        this.displayedColumns.unshift('actions');
        this.setDatasourceOptions();
      }
      this.resetPaginationAndSorting();
    }
  }
  setDatasourceOptions() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  resetPaginationAndSorting() {
    if (this.paginator && this.sort) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 15;
      this.sort.sort({ id: '', start: 'asc', disableClear: false });
    }
  }
  getTableData(data: any[]): any[] {
    let tableData: any[] = [];
    data.forEach((x: any) => {
      let value = {};

      if (this.config.mode === 'mini') {
        this.config.mini.forEach((y: any) => {
          if (x[y]) {
            value[y] = x[y];
          } else {
            value[y] = '';
          }
        });
      } else {
        this.config.display.forEach((y: any) => {
          if (x[y]) {
            value[y] = x[y];
          } else {
            value[y] = '';
          }
        });
      }
      tableData.push(value);
    });

    if (this.config.mode === 'mini') {
      tableData = tableData.length > 3 ? tableData.slice(0, 3) : tableData;
    }

    return tableData;
  }

  ngAfterViewInit(): void {
    this.setDatasourceOptions();
  }

  viewBtnClickAction(rowData: any) {
    if (this.config.view?.display) {
      this.viewModel = this.initialData.find((x) => x.id == rowData.id);
      this.dialogData = { title: 'View Item' };
      const dialogRef = this.dialog.open(this.viewContainer, {
        height: 'auto',
        width: '80%',
      });
    } else {
      this.viewAction.emit(rowData);
    }
  }

  deleteInfoAction(rowData: any) {
    this.deleteAction.emit(rowData);
    this.dialog.closeAll();
  }
  editBtnClickAction(rowData: any) {
    if (this.config.edit) {
      this.editModel = this.initialData.find((x) => x.id == rowData.id);
      this.dialogData = { title: 'Edit Item', ...rowData };
      const dialogRef = this.dialog.open(this.editContainer, {
        height: 'auto',
        width: '80%',
      });
    } else {
      this.editAction.emit(rowData);
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getObjectKeys(model: any, modelType: string) {
    let array;
    if (modelType === 'edit') array = this.config.edit.display;
    else array = Object.keys(model);
    array.sort(function (x, y) {
      return x == 'id' ? -1 : y == 'id' ? 1 : 0;
    });
    return array;
  }
  disableField(name: string) {
    return this.config.edit.disable.filter((x) => x === name).length > 0;
  }
  displayField(name: string) {
    return this.config.edit.display.filter((x) => x === name).length > 0;
  }
  isRequired(name: string) {
    return !(this.config.edit.optional?.filter((x) => x === name).length > 0);
  }

  editInfoAction() {
    if (!this.editInfoForm.valid) return;
    this.editAction.emit(this.editModel);
    this.dialog.closeAll();
  }

  deleteBtnClickAction(rowData: any) {
    this.deleteModel = rowData;
    this.dialogData = { title: 'Delete Item', ...rowData };
    const dialogRef = this.dialog.open(this.deleteContainer, {
      height: 'auto',
      width: '80%',
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.galleryData = this.initialData.filter(
      (x) => JSON.stringify(x).search(filterValue) != -1
    );
    this.setDatasourceOptions();
  }

  fileImportBtnClick() {
    if (!this.importFile) return;

    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.importFile);
    fileReader.onload = (e) => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let exceldata = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.importAction.emit(exceldata);
    };
  }

  importActionBtnClick() {
    const dialogRef = this.dialog.open(this.importContainer, {
      height: '50%',
      width: '80%',
    });
  }

  onSelectFile(event: any) {
    if (!event.target.files[0]) return;

    this.importFile = event.target.files[0];
  }

  async getDropdownValueAsArray(documentId: { path: string; value: string }) {
    let result = new Subject<any[]>();
    this.store
      .doc(documentId.path)
      .get()
      .subscribe((doc) => {
        if (doc.exists) {
          let value = doc.data();
          result.next(value[documentId.value]);
        }
      });
    return result.asObservable();
  }
  exportAction(): void {
    const fileName = 'export-data';
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      JSON.parse(
        JSON.stringify(
          this.initialData.map((data) => {
            let keys = Object.keys(data);
            let tempData: any = {};
            keys.forEach((item) => {
              if (Array.isArray(data[item]))
                tempData[item] = JSON.stringify(data[item]);
              else tempData[item] = data[item];
            });
            return tempData;
          })
        )
      )
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
